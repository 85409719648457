//  base URL
//const baseURL = "http://dev.dcirrus.info/api.acms/v1/"

import domainMapping from "../Utlitiy/domainMapping"

const baseURL = domainMapping[window.location.protocol + "//" + window.location.host] + "/api.acms/v1/"
//console.log("baseurl----------------------->"+baseURL);
// const baseURL = "https://dcirrus.io/api.acms/v1/"
// const baseURL = "https://dcirrus.co.in/api.acms/v1/"

const backend_server = window.location.protocol + "//" + window.location.host + "/api/"
//const backend_server = "http://localhost:8080/api/"

//console.log("backend_server----------------------->"+backend_server);

const urls = {
  baseURL,
  captcha: baseURL + "new/cpt/0/loginFetchCaptchaAfter",
  login: baseURL + "publicapi/login/0/loginsuccess",
  generateToken: backend_server + "upload/generate/session",

  createFolder:
    baseURL + "app/unindexfolderaddauth/0/web/admAddNewFolderServiceAfter",

  getFolders:
    baseURL +
    "app/unindexfolderlistg/0/zerolevel/0/P/fetchAllAdmFolderListResponse",

  createCreditorFolder: backend_server + "upload/create/folder/url",
  fileUpload: backend_server + "upload/generate/presigned/url",
  updateMetaData:
  backend_server + "upload/update/document/metadata",

  generateSessionToken: baseURL + "publicapi/token/0/generate/temp/token/timebound",
  creditorDetails: backend_server + "creditordetails/create",
  getCreditorDetails: backend_server + "creditordetails/",
  fileDetails: backend_server + "filedetails/create",
  urlShortener: backend_server + "urlshortener/",
  totalClaims: backend_server + "totalclaims/",
  captchaGeneration: backend_server + "captcha/create",
  captchaVerfication: backend_server + "captcha/verify",
  otpGeneration: backend_server + "otp/create",
  otpVerfication: backend_server + "otp/verify",
  sendEmailToClaimant: backend_server + "send/claimant",
  sendEmailToRP: backend_server + "send/rp",
  exportlogs: backend_server + "exportlogs",
  getCreditorDetailsByEmailForm: backend_server + "creditordetails/creditordetailsemailform",
  getCreditorDetailsByClaimIdIdProof: backend_server + "creditordetails/creditorDetailsByClaimIdIdProof",
  updateCreditorDetails: backend_server + "creditordetails/update",
  holdinfo: backend_server + "otp/holdinfo",
  getinfo: backend_server + "otp/getinfo",
  cleardata: backend_server + "otp/cleardata",
  sendotpmobile: backend_server + "otp/sendotp",

  updateLoginLog: backend_server + "loginlog/createloginlog",
}
export default urls
