import { toast, ToastContainer } from "react-toastify"
import { otpGeneration, otpVerification } from "../APIFolder/api"

const notification = {
  filesUploaded: fileName => {
    toast.success(`${fileName} successfully uploaded`)
  },
  filesAlreadyUploaded: fileName => {
    toast.info(`${fileName} already uploaded`)
  },
  filesNotUploaded: fileName => {
    toast.error(`${fileName} not uploaded`)
  },
  folderCreated: folderName => {
    toast.success(`${folderName} succesfully created`)
  },
  folderAlreadyCreated: folderName => {
    toast.info(`${folderName} already created`)
  },
  folderNotCreated: folderName => {
    toast.error(`${folderName} not created`)
  },
  urlGenerated: () => {
    toast.success(`Url successfully generated`)
  },
  urlNotGenerated: () => {
    toast.error(`url not generated`)
  },
  someProblem: () => {
    toast.error(`There is some technical problem. Please logout and login again.`)
  },
  captchaNotMatched:()=>{
    toast.error(`Captcha not matched. Click on Captcha to refresh again.`)
  },
  otpGeneration:()=>{
    toast.success(`OTP has been sent to Email Id entered. Please check spam/junk in your mailbox.`)
  },
  otpVerificationSuccess:()=>{
    toast.success(`OTP has been successfully matched.`)
  },
  otpVerficationFailed:()=>{
    toast.error(`OTP did not match. Try again`)
  }
  ,
  emailSendClaimantSuccess:()=>{
    toast.success(`Email has been sent to Claimant.`)
  },
  emailSendClaimantFailed:()=>{
    toast.error(`Email has been failed to send to the Claimant`)}
    ,
    emailSendRPSuccess:()=>{
      toast.success(`Email has been sent to Resolution Professional.`)
    },
    emailSendRPFailed:()=>{
      toast.error(`Email has been failed to send to the RP`)}
  ,
  rootFolderNotSelected:()=>{
    toast.info(`Folder not selected.`)
  },
  wrongCaptcha:()=>{
    toast.info(`Wrong Captcha. Try again`)
  },
  wrongCredentials:()=>{
    toast.info(`Wrong Credentials. Try again`)
  },
  accountExpired:()=>{
    toast.error(`Your account is expired, Please contact our support team.`)
  },
  accoundBlocked:()=>{
    toast.error(`Your account has been blocked. Please do forgot password on DCirrus Drive to access your account again.`)
  },
  emptySubFolders:()=>{
    toast.error(`Sub Folders were not created. Try again with New Folder. `)
  },
  sessionExpired:()=>{
    toast.error(`Session is Expired. Please refresh the screen.`)
  },
  savedAsDraftSuccess:()=>{
    toast.success(`Your Claim has been saved as a draft successfully.`)
  },
  missingmandatoryfields:()=>{
    toast.error(`Can not complete operation. Please enter Mandatory fields with marked with *`)
  },
  validAdhaarNumber:()=>{
    toast.error(`Please enter valid Adhaar Card number.valid form XXXX XXXX XXXX`)
  },
  validPanNumber:()=>{
    toast.error(`Please enter valid PAN Card number.`)
  },
  validPassportNumber:()=>{
    toast.error(`Please enter valid Passport number.`)
  },
  validVoterIDCard:()=>{
    toast.error(`Please enter valid Voter ID Card number.`)
  },
  validAmountClaimed:()=>{
    toast.error(`Amount should be greater than 0.`)
  },
  tryAgain:()=>{
    toast.error("Unexpected Error happened. Please try again after some time!")
  },
  claimNotFound:()=>{
    toast.error("Please check your claim id, email id and identification no properly. You have not filed claim yet.")
  },
  selectidentificationtype:()=>{
    toast.error("Please select identification type.")
  },
  validsurrendervalue:()=>{
    toast.error("Please enter surrender value greater than 0 and less than equal to 9999999.")
  },
  validAmount:()=>{
    toast.error(`Claim Amount and Interest Amount should be greater than 0 and less than equal to 9999999.`)
  },
  validExpiryDate:()=>{
    toast.error(`Deposit date should be greater than 1st Jan 1997 and less than 31st Dec 2030.`)
  },
  disclaimercheck:()=>{
    toast.error(`Please accept the disclaimer before saving your data.`)
  },
  tooltipname:()=>{
    toast.info(`Enter self name in case claim filed by Self, Nominee name if claim filed by Nominee,
    Successor name in filed by Successor. In case claim filed by Nominee / Successor,
    proof of Nomination / Succession to be uploaded.`)
  },
  tooltipaddress:()=>{
    toast.info(`Enter Valid address with Pin code.`)
  },
  tooltipdocdetails:()=>{
    toast.info(`Enter details of Membership Certificate / Receipt / any other documents in case of
    claim filed under schemes.`)
  },
  tooltipdebtoccured:()=>{
    toast.info(`Enter "Enrolled in Scheme introduced."
    in case claim arising due to enrollment in time share scheme.`)
  },
  tooltipmutualcredit:()=>{
    toast.info(`Any debt owed by the creditor/claimant to the Corporate Debtor or any
    amount due and payable by the creditor/claimant to the Corporate Debtor in furtherance
    of any agreement or arrangement between the Claimant and the Corporate Debtor.
    Such debt shall be adjusted from the claim amount under the claim form.`)
  },
  tooltipdetailssecurityheld:()=>{
    toast.info(`Details ofany interest created on the asset of the CD or any person acting on behalf of CD,
    securing the repayment of debt to the creditor, value of the security and the date on
    which the security interest was created on the asset.
    Insert "Unsecured" in case claim arising due to enrollment in time share scheme.`)
  },
  tooltipbankaccount:()=>{
    toast.info(`NameofBeneficiaryAccount NumberIFSC CodeBank Name`)
  },
  tooltipsignaturepositionaddress:()=>{
    toast.info(`If claim filed on behalf of Self enter your own details, if filed by Nominee /
    Successor enter Nominee / Successor details and upload proof of nomination and succession.`)
  },
  tooltiprelatedparty:()=>{
    toast.info(`Related Party is defined under Section 5(24) of the Insolvency Bankruptcy Code as under:
    5. Definitions
    …..
    (24) “related party”, in relation to a corporate debtor, means-
    (a) a director or partner of the corporate debtor or a relative of a director or partner of the corporate debtor;
    (b) key managerial personnel of the corporate debtor or a relative of a key managerial personnel of the corporate debtor;
    (c) a limited liability partnership or a partnership firm in which a director, partner, or manager of the corporate debtor or his relative is a partner;
    (d) a private company in which a director, partner or manager of the corporate debtor is a director and holds along with his relatives, more than two per cent. of its share capital;
    (e) a public company in which a director, partner or manager of the corporate debtor is a director and holds along with relatives, more than two per cent. of its paid- up share capital;
    (f) anybody corporate whose board of directors, managing director or manager, in the ordinary course of business, acts on the advice, directions or instructions of a director, partner or manager of the corporate debtor;
    (g) any limited liability partnership or a partnership firm whose partners or employees in the ordinary course of business, acts on the advice, directions or instructions of a director, partner or manager of the corporate debtor;
    (h) any person on whose advice, directions or instructions, a director, partner or manager of the corporate debtor is accustomed to act;
    (i) a body corporate which is a holding, subsidiary or an associate company of the corporate debtor, or a subsidiary of a holding company to which the corporate debtor is a subsidiary;
    (j) any person who controls more than twenty per cent. of voting rights in the corporate debtor on account of ownership or a voting agreement;
    (k) any person in whom the corporate debtor controls more than twenty per cent. of voting rights on account of ownership or a voting agreement;
    (l) any person who can control the composition of the board of directors or corresponding governing body of the corporate debtor;
    (m) any person who is associated with the corporate debtor on account of-
    (i) participation in policy making processes of the corporate debtor; or
    (ii) having more than two directors in common between the corporate debtor and such person; or
    (iii) interchange of managerial personnel between the corporate debtor and such person; or
    (iv) provision of essential technical information to, or from, the corporate debtor;`)
  },
  tooltipidprooftype:()=>{
    toast.info(`Enter self-identification proof in case claim filed on behalf of Self,
    Nominee identification proof if claim filed by Nominee, Successor identification proof
    in case claim filed by Successor. In case claim filed by Nominee / Successor,
    proof of Nomination / Succession to be uploaded.`)
  },
  tooltipidproof:()=>{
    toast.info(`Enter identification number for type of ID proof selected.`)
  },
  tooltipfolio:()=>{
    toast.info(`Please enter your 12 digit fdr number.`)
  },
  tooltipprincipalamount:()=>{
    toast.info(`Please enter claim amount.`)
  },
  tooltipsurrenderval:()=>{
    toast.info(`Sum of Surrender value forunutilized room nights mentioned in Membership Certificates / Receipts.`)
  },
  tooltiptotalamount:()=>{
    toast.info(`Please enter interest amount.`)
  },
  tooltipexpirydate:()=>{
    toast.info(`Please enter deposit date.`)
  },
  tooltipmembnumber:()=>{
    toast.info(`Please enter your 12 digit fdr number.`)
  },
  tooltipcustomerid:()=>{
    toast.info(`Please enter your customer id.`)
  },
  tooltipbankname:()=>{
    toast.info(`Please enter bank name for claim processing.`)
  },
  tooltipifsccode:()=>{
    toast.info(`Please enter ifsc code of 11 characters.`)
  },
  tooltipbankaccount:()=>{
    toast.info(`Please enter bank account number of 9 to 18 digits.`)
  },
  tooltipschematype:()=>{
    toast.info(`choose scheme type cumulative or non cumulative.`)
  },
  tooltipschemaperiod:()=>{
    toast.info(`Scheme period is added to deposit date to get the maturity date.`)
  },
  tooltipvendorcode:()=>{
    toast.info(`Please enter vendor code.`)
  },
  tooltipemployeecode:()=>{
    toast.info(`Please enter employee code.`)
  },
  tooltipsbeneficaryname:()=>{
    toast.info(`Please enter beneficiary name.`)
  },
  validateselectrelateddebtor:()=>{
    toast.error(`Please select whether related party of the corporate debtor, as defined under section 5 (24) of the Code.`)
  },
  validateuploadfiletypes:()=>{
    toast.error(`Only pdf, jpg, jpeg, png file types are allowed.`)
  },
  validateuploadfilesize:()=>{
    toast.error(`Maximum file size to upload is 3 MB.`)
  },
  hide:()=>{
    toast.dismiss();
  },

}

export default notification
