import React, { useState, useEffect } from "react";
import { Dropdown, Form, ListGroup, Row, Table } from "react-bootstrap";
import "./style.css";


function FormB(props) {
  const [finCredtr, SetFinCredtr] = useState("");
  const [dropDownValue, SetDropDownValue] = useState("Select");
  const [dropDownIam, SetDropDownIm] = useState("Select");

  const { secondScreen, singlecreditorobj } = props
 //  console.log("fomrsca props--------->", props);

  useEffect(() => {
    var insolvency_professional = null
    insolvency_professional = singlecreditorobj?.insolvency_professional
    SetDropDownValue(insolvency_professional)

    if(singlecreditorobj?.from_name){
      document.getElementById("txt_from_name").value = singlecreditorobj?.from_name
      document.getElementById("lbl_creditor_name").innerHTML = singlecreditorobj?.from_name
      document.getElementById("lbl_from_name").innerHTML = singlecreditorobj?.from_name
      document.getElementById("lbl_claimant_name").innerHTML = singlecreditorobj?.from_name
      document.getElementById("lbl_cred_name").innerHTML = singlecreditorobj?.from_name
    }

    if(singlecreditorobj?.from_address){
      document.getElementById("txt_from_address").value = singlecreditorobj?.from_address
      document.getElementById("lbl_from_address").innerHTML = singlecreditorobj?.from_address
      document.getElementById("lbl_addremail").innerHTML = singlecreditorobj?.from_address + ", " + secondScreen.email_id
      document.getElementById("lbl_declartion_address").innerHTML = singlecreditorobj?.from_address + ", " + secondScreen.email_id
    }

    if(singlecreditorobj?.idf_not_formca){
      document.getElementById("lbl_identification_doc").innerHTML = singlecreditorobj?.idf_not_formca
    }
    if(singlecreditorobj?.registration_number && document.getElementById("lbl_identification_doc")){
      document.getElementById("lbl_identification_doc").innerHTML = singlecreditorobj?.registration_number;
    }

    if(singlecreditorobj?.from_address){
      document.getElementById("lbl_addremail").innerHTML = singlecreditorobj?.from_address + ", " + secondScreen.email_id
    }

    if(document.getElementById("txt_doc_details") && singlecreditorobj?.doc_reference){
      document.getElementById("txt_doc_details").value = singlecreditorobj?.doc_reference
      document.getElementById("lbl_doc_details").innerHTML = singlecreditorobj?.doc_reference
    }

    if(document.getElementById("txt_debt_incurred") && singlecreditorobj?.details_debt_occured){
      document.getElementById("txt_debt_incurred").value = singlecreditorobj?.details_debt_occured
      document.getElementById("lbl_debt_incurred").innerHTML = singlecreditorobj?.details_debt_occured
    }

    if(document.getElementById("txt_mutual_credit") && singlecreditorobj?.details_mutual_credit_debt){
      document.getElementById("txt_mutual_credit").value = singlecreditorobj?.details_mutual_credit_debt
      document.getElementById("lbl_mutual_credit").innerHTML = singlecreditorobj?.details_mutual_credit_debt
    }

    if(document.getElementById("txt_details_security_held") && singlecreditorobj?.details_security_held){
      document.getElementById("txt_details_security_held").value = singlecreditorobj?.details_security_held
      document.getElementById("lbl_details_security_held").innerHTML = singlecreditorobj?.details_security_held
    }

    if(document.getElementById("txt_bank_account") && singlecreditorobj?.details_bank_account){
      document.getElementById("txt_bank_account").value = singlecreditorobj?.details_bank_account
      document.getElementById("lbl_bank_account").innerHTML = singlecreditorobj?.details_bank_account
    }

    if(document.getElementById("txt_doc_attach_list") && singlecreditorobj?.details_debt_occured){
      document.getElementById("txt_doc_attach_list").value = singlecreditorobj?.documents_attached
      document.getElementById("lbl_doc_attach_list").innerHTML = singlecreditorobj?.documents_attached
    }

    if(singlecreditorobj?.dispute_details && document.getElementById("txt_dispute_details")){
      document.getElementById("txt_dispute_details").value = singlecreditorobj?.dispute_details;
      document.getElementById("lbl_dispute_details").innerHTML = singlecreditorobj?.dispute_details;
    }

    if(singlecreditorobj?.name_in_block_letter){
      document.getElementById("txt_name_in_block_letter").value = singlecreditorobj?.name_in_block_letter
      document.getElementById("lbl_name_in_block_letter").innerHTML = singlecreditorobj?.name_in_block_letter
    }

    if(singlecreditorobj?.position_with_creditor){
      document.getElementById("txt_creditor_position").value = singlecreditorobj?.position_with_creditor
      document.getElementById("lbl_creditor_position").innerHTML = singlecreditorobj?.position_with_creditor
    }

    if(singlecreditorobj?.address_person_signing){
      document.getElementById("txt_address_signing").value = singlecreditorobj?.address_person_signing
      document.getElementById("lbl_address_signing").innerHTML = singlecreditorobj?.address_person_signing
    }

    if(singlecreditorobj?.related_corporate_debtor){
      document.getElementById("dropdown-12").value = singlecreditorobj?.related_corporate_debtor
      document.getElementById("lbl_dropdown_12").innerHTML = singlecreditorobj?.related_corporate_debtor
    }

  }, [singlecreditorobj])

  useEffect(() => {
    var date = new Date();
    document.getElementById("lbl_form_date").innerHTML = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
  }, []);

   //console.log("fomrscreen : ", secondScreen)


  function handleChange(event) {
   // console.log("handleChange : " + event.target.value + " :: " + event.target.id);
    var lblid = event.target.id.replace("txt_", "lbl_");
    document.getElementById(lblid).innerHTML = event.target.value;
   // console.log("1w1w1w1w1w :: " + document.getElementById(lblid).innerHTML);

    if (event.target.id == "txt_from_name") {
      if (event.target.value != "") {
        document.getElementById("lbl_cred_name").innerHTML = event.target.value;
        document.getElementById("lbl_claimant_name").innerHTML = event.target.value;
        document.getElementById("lbl_creditor_name").innerHTML = event.target.value;
        document.getElementById("txt_name_in_block_letter").value = event.target.value;
        document.getElementById("lbl_ver_name").innerHTML = event.target.value;
      } else {
        document.getElementById("lbl_cred_name").innerHTML = "[Name of the financial creditor]";
        document.getElementById("lbl_claimant_name").innerHTML = "[Name of claimant]";
        document.getElementById("lbl_creditor_name").innerHTML = "";
        document.getElementById("lbl_ver_name").innerHTML = "[Name]";
      }
    }

    if (event.target.id == "txt_from_address") {
      if (event.target.value != "") {
        document.getElementById("lbl_addremail").innerHTML = event.target.value + ", " + secondScreen.email_id;
        document.getElementById("lbl_declartion_address").innerHTML = event.target.value;
        document.getElementById("txt_address_signing").value = document.getElementById("lbl_addremail").innerHTML;
      } else {
        document.getElementById("lbl_addremail").innerHTML = "";
        document.getElementById("lbl_declartion_address").innerHTML = "……………………………………………………… …………………………………………………";
      }
    }

    if (event.target.id == "txt_doc_details") {
      if (event.target.value != "") {
        document.getElementById("lbl_doc_list").innerHTML = event.target.value;
      } else {
        document.getElementById("lbl_doc_list").innerHTML = "[Please list the documents relied on as evidence of claim]";
      }
    }

    if (event.target.id == "txt_mutual_credit") {
      if (event.target.value != "") {
        document.getElementById("lbl_saidsum").innerHTML = event.target.value;
      } else {
        document.getElementById("lbl_saidsum").innerHTML = "[Please state details of any mutual credit, mutual debts, or other mutual dealings between the corporate debtor and the creditor which may be set-off against the claim]";
      }
    }
  }
  function handleChangeinsolvencypref(event){
    document.getElementById("lbl_dropdown_11").innerHTML = event.target.value;
    SetDropDownValue(event.target.value)
  }

  function handleChangeiam(event) {
    document.getElementById("lbl_dropdown_12").innerHTML = event.target.value;
    SetDropDownValue(event.target.value)
  }

  var amt = parseInt(secondScreen.amount_claimed) + parseInt(secondScreen.creditor)

  return (
    <Row>
      <div className="container-fluid formCA" id="formB">
        <div className="heading">
          <h6 className="subHeading headingFormat">FORM B</h6>
          <h6 className="subHeading headingFormat">
            PROOF OF CLAIM BY OPERATIONAL CREDITORS EXCEPT WORKMEN AND EMPLOYEES
          </h6>
          <p>
          (Under Regulation 7 of the Insolvency and Bankruptcy Board of India
            (Insolvency Resolution Process for Corporate Persons) Regulations, 2016)
          </p>
        </div>
        <div className="section1">
          <div className="from ">
            <p><span className={"mandatorysigncls mandatorysignclsempty"}>*</span>From</p>
            <Form>
              <Form.Group>
                <Form.Control required type="text" id="txt_from_name" onChange={handleChange} className="txtallclasses" placeholder="Name" />
                <label id='lbl_from_name' className="lblallclasses displaynone"></label>
              </Form.Group>
            </Form>
          </div>

          <div className="from">
            <p>Date</p>
            <Form>
              <Form.Group>
                <label id="lbl_form_date"></label>
              </Form.Group>
            </Form>
          </div>
        </div>
        <div className="section1">
          <div className="from ">
            <Form>
              <Form.Group>
              <span className={"mandatorysigncls mandatorysignclsempty"}>*</span>
              <textarea required cols="50" className="width100perc txtallclasses" id="txt_from_address" onChange={handleChange} placeholder="Address" />
                <label id='lbl_from_address' className="lblallclasses displaynone"></label>
              </Form.Group>
            </Form>
          </div>
        </div>
        <div className="to">
          <div className="from"> To</div>
          <div className="from">
            The Interim Resolution Professional / Resolution Professional
            <br />
            Rajesh Sureshchandra Sheth (IBBI/IPA-002/IP-NO1021/2020-2021/13298)
            <br />
            <b>Correspondence Address</b>: BDO Restructuring Advisory LLP, Level 9, The Ruby, North West Wing, Senapati Bapat Road, Dadar (W), Mumbai 400028, Maharashtra, India
            <br />
            <b>Registered Address</b>: B-55, Shatdal society, 7th Floor ,Azad Lane, off S.V. Road, Andheri West ,Near shoppers Stop ,Mumbai ,Maharashtra -400058

          </div>
          <Form>
            <Form.Group>
              <label></label>
            </Form.Group>
          </Form>
        </div>
        <div className="subject">
          <p>Subject: Submission of proof of claim.</p>
        </div>
        <div className="formBody">
          <p>Madam/Sir,</p>
          <div className="txtjustify">
            <span id="lbl_cred_name" >[Name of the financial creditor]</span>, hereby submits this proof of claim
            in respect of the corporate insolvency resolution process in the case of  Karkinos Healthcare Pvt. Ltd.
            The details for the same are set out below:
          </div>
        </div>
        <Table className="table1" size="sm" bordered hover>
          <tbody>
            <tr className="headtr">
              <td colSpan={3}>PARTICULARS</td>
            </tr>
            <tr>
              <td>1. </td>
              <td>NAME OF OPERATIONAL CREDITOR</td>
              <td className="test">
                <Form>
                  <Form.Group>
                    <label id='lbl_creditor_name' className="lblallclasses" ></label>
                  </Form.Group>
                </Form>
              </td>
            </tr>
            <tr>
              <td>2. </td>
              <td>
                IDENTIFICATION NUMBER OF OPERATIONAL CREDITOR
                (IF AN INCORPORATED BODY PROVIDE IDENTIFICATION NUMBER AND PROOF OF INCORPORATION.
                IF A PARTNERSHIP OR INDIVIDUAL PROVIDE IDENTIFICATION RECORDS* OF ALL THE PARTNERS OR THE INDIVIDUAL)
              </td>
              <td className="test">
                <Form>
                  <Form.Group>
                    <label id='lbl_identification_doc' className="lblallclasses"></label>
                  </Form.Group>
                </Form>
              </td>
            </tr>
            <tr>
              <td>3. </td>
              <td>
                ADDRESS AND EMAIL ADDRESS OF OPERATIONAL CREDITOR FOR CORRESPONDENCE
              </td>
              <td className="test">
                <Form>
                  <Form.Group>
                    <label id='lbl_addremail' className="lblallclasses"></label>
                  </Form.Group>
                </Form>
              </td>
            </tr>
            <tr>
              <td>4. </td>
              <td>TOTAL AMOUNT OF CLAIM (in Rs.)
                (INCLUDING ANY INTEREST AS AT THE INSOLVENCY COMMENCEMENT DATE)
              </td>
              <td className="test">
                <Form>
                  <Form.Group>
                  <span id="span_amount_form"></span>
                  </Form.Group>
                </Form>
              </td>
            </tr>
            <tr>
              <td>5. </td>
              <td>
                <span className={"mandatorysigncls mandatorysignclsempty"}>*</span>
                DETAILS OF DOCUMENTS BY REFERENCE TO WHICH THE DEBT CAN BE SUBSTANTIATED.
              </td>
              <td className="test">
                <Form>
                  <Form.Group>
                    <span className={"mandatorysigncls mandatorysignclsempty"}>*</span>
                    <textarea required className="width100perc txtallclasses" id="txt_doc_details" onChange={handleChange} />
                    <label id='lbl_doc_details' className="lblallclasses displaynone"></label>
                  </Form.Group>
                </Form>
              </td>
            </tr>
            <tr>
              <td>6. </td>
              <td>
                <span className={"mandatorysigncls mandatorysignclsempty"}>*</span>
                DETAILS OF ANY DISPUTE AS WELL AS THE RECORD OF PENDENCY OR ORDER OF SUIT OR ARBITRATION PROCEEDINGS
              </td>
              <td className="test">
                <Form>
                  <Form.Group>
                    <span className={"mandatorysigncls mandatorysignclsempty"}>*</span>
                    <textarea required className="width100perc txtallclasses" id="txt_dispute_details" onChange={handleChange} />
                    <label id='lbl_dispute_details' className="lblallclasses displaynone"></label>
                  </Form.Group>
                </Form>
              </td>
            </tr>
            <tr>
              <td>7. </td>
              <td>
                <span className={"mandatorysigncls mandatorysignclsempty"}>*</span>
                DETAILS OF HOW AND WHEN DEBT INCURRED
              </td>
              <td className="test">
                <Form>
                  <Form.Group>
                    <span className={"mandatorysigncls mandatorysignclsempty"}>*</span>
                    <textarea required className="width100perc txtallclasses" rows="3" id="txt_debt_incurred" onChange={handleChange} />
                    <label id='lbl_debt_incurred' className="lblallclasses displaynone"></label>
                  </Form.Group>
                </Form>
              </td>
            </tr>
            <tr>
              <td>8. </td>
              <td>
                <span className={"mandatorysigncls mandatorysignclsempty"}>*</span>
                DETAILS OF ANY MUTUAL CREDIT, MUTUAL DEBTS, OR OTHER MUTUAL DEALINGS
                BETWEEN THE CORPORATE DEBTOR AND THE CREDITOR WHICH MAY BE SET-OFF AGAINST THE CLAIM
              </td>
              <td className="test">
                <Form>
                  <Form.Group>
                    <span className={"mandatorysigncls mandatorysignclsempty"}>*</span>
                    <textarea required className="width100perc txtallclasses" rows="3" id="txt_mutual_credit" onChange={handleChange} />
                    <label id='lbl_mutual_credit' className="lblallclasses displaynone"></label>
                  </Form.Group>
                </Form>
              </td>
            </tr>
            <tr>
              <td>9. </td>
              <td>
                <span className={"mandatorysigncls mandatorysignclsempty"}>*</span>
                DETAILS OF:
                  <br/>a.	any security held, the value of security and its date, or
                  <br/>b.	any retention of title arrangement in respect of goods or properties to which the claim refers
              </td>
              <td className="test">
                <Form>
                  <Form.Group>
                    <span className={"mandatorysigncls mandatorysignclsempty"}>*</span>
                    <textarea required className="width100perc txtallclasses" rows="3" id="txt_details_security_held" onChange={handleChange} />
                    <label id='lbl_details_security_held' className="lblallclasses displaynone"></label>
                  </Form.Group>
                </Form>
              </td>
            </tr>
            <tr>
              <td>10. </td>
              <td>
                <span className={"mandatorysigncls mandatorysignclsempty"}>*</span>
                DETAILS OF THE BANK ACCOUNT TO WHICH THE AMOUNT OF
                THE CLAIM OR ANY PART THEREOF CAN BE TRANSFERRED PURSUANT TO A RESOLUTION PLAN
              </td>
              <td className="test">
                <Form>
                  <Form.Group>
                    <span className={"mandatorysigncls mandatorysignclsempty"}>*</span>
                    <textarea required className="width100perc txtallclasses" rows="3" id="txt_bank_account" disabled onChange={handleChange} />
                    <label id='lbl_bank_account' className="lblallclasses displaynone"></label>
                  </Form.Group>
                </Form>
              </td>
            </tr>
            <tr>
              <td>11. </td>
              <td>
                <span className={"mandatorysigncls mandatorysignclsempty"}>*</span>
                LIST OF DOCUMENTS ATTACHED TO THIS PROOF OF CLAIM IN ORDER TO PROVE
                THE EXISTENCE AND NON-PAYMENT OF CLAIM DUE TO THE OPERATIONAL CREDITOR
              </td>
              <td className="test">
                <Form>
                  <Form.Group>
                    <span className={"mandatorysigncls mandatorysignclsempty"}>*</span>
                    <textarea required className="width100perc txtallclasses" rows="3" id="txt_doc_attach_list" onChange={handleChange} />
                    <label id='lbl_doc_attach_list' className="lblallclasses displaynone"></label>
                  </Form.Group>
                </Form>
              </td>
            </tr>
          </tbody>
        </Table>
        <Table className="table2" size="sm" bordered hover>
          <tbody>
            <tr className="headtr">
              <td colSpan={3}>
                <p className="pt-2 margintop2px">
                  Signature of operational creditor or person authorised to act on his behalf
                </p>
                <p className="">
                  [Please enclose the authority if this is being submitted on behalf of an operational creditor]
                </p>
              </td>
            </tr>
            <tr className="headtr">
              <td colSpan={3}><span className={"mandatorysigncls mandatorysignclsempty"}>*</span>
                Name in BLOCK LETTERS : <Form.Control required type="text" id="txt_name_in_block_letter"
                onChange={handleChange} className="txtallclasses capitalletters" />
                <label id='lbl_name_in_block_letter' className="lblallclasses displaynone"></label></td>
            </tr>
            <tr className="headtr">
              <td colSpan={3}><span className={"mandatorysigncls mandatorysignclsempty"}>*</span>
                Position with or in relation to creditor :
                <textarea className="width100perc txtallclasses" required rows="3" id="txt_creditor_position" onChange={handleChange} />
                <label id='lbl_creditor_position' className="lblallclasses displaynone"></label></td>
            </tr>
            <tr className="headtr">
              <td colSpan={3}><span className={"mandatorysigncls mandatorysignclsempty"}>*</span>
                Address of person signing :
                <textarea className="width100perc txtallclasses" required rows="3" id="txt_address_signing" onChange={handleChange} />
                <label id='lbl_address_signing' className="lblallclasses displaynone"></label></td>
            </tr>
          </tbody>
        </Table>
        *PAN number, passport, AADHAAR Card or the identity card issued by the Election Commission of India.
        <div className="">
          <div className="heading">
            <h6 className=" heading headingFormat">DECLARATION</h6>
          </div>
          <p>
            I, <label id="lbl_claimant_name">[Name of claimant]</label>, currently residing at
            &nbsp;<label id="lbl_declartion_address">……………………………………………………… …………………………………………………</label>, do
            hereby declare and state as follows: -{" "}
          </p>
          <ol>
            <li><p>
              Karkinos Healthcare Pvt. Ltd., the corporate debtor was, at the
              insolvency commencement date, being the 21st day of May 2024, actually indebted to me for a sum of Rs.
              <span id="span_from_insert_claim_amount">{amt > 0 ? amt : "[insert amount of claim]"}</span>
            </p></li>
            <li><p>
              In respect of my claim of the said sum or any part thereof, I
              have relied on the documents specified below:<br />
              <label id="lbl_doc_list">[Please list the documents relied on as evidence of claim]</label>.
            </p></li>
            <li><p>
              The said documents are true, valid and genuine to the best of my knowledge,
              information and belief and no material facts have been concealed therefrom.
            </p></li>
            <li><p>
              In respect of the said sum or any part thereof, neither I, nor any person,
              by my order, to my knowledge or belief, for my use, had or received any manner
              of satisfaction or security whatsoever, save and except the following:<br />
              <label id="lbl_saidsum">[Please state details of any mutual credit, mutual debts, or other mutual dealings between the corporate debtor and the creditor which may be set-off against the claim]</label>
            </p></li>
          </ol>
          <div>
            <div className="d-flex justify-content-between"><p>Date :</p> </div>
            <div className="d-flex justify-content-between"><p>Place :</p> <p>(Signature of the claimant)</p></div>
            <p className="text-center font-weight-bolder">VERIFICATION</p>
            <p>I, <label id="lbl_ver_name">[Name]</label> the claimant hereinabove, do hereby verify that the contents of this proof of claim are true and correct to my knowledge and belief and no material fact has been concealed therefrom.
            </p>
            <p>Verified at ………………………… on this ………………… day of …………, 20…………
            </p>
            <p className="text-right">(Signature of claimant)
            </p>
            <p>[Note: In the case of company or limited liability partnership, the declaration and verification shall be made by the director/manager/secretary and in the case of other entities, an officer authorized for the purpose by the entity.]
            </p>
          </div>
        </div>
      </div>
    </Row>
  );
}

export default FormB;
