import ReactLoading from "react-loading"

const StopScreen= ()=>{

return <div style={{position:"absolute",width:document.body.offsetWidth,height:document.body.offsetHeight,backgroundColor:"black",zIndex:"10",opacity:"0.2"}} >
    <div style={{position:"absolute",top:"50%",left:"40%"}}>
    <ReactLoading style={{width:"100px",fill:"white"}} type="spinningBubbles" color="black" />
    </div>
    </div>
}

export default StopScreen