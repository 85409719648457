import { Row, Col, Form, Button, Modal, Dropdown } from "react-bootstrap";
import style from "./style.module.css";
import React, { useState, useEffect } from "react";
import dragIcon from "../../Assets/drag_and_drop.png";
import close from "../../Assets/close_light_bg.png";
import {
  createCreditorFolder,
  updateUploadingFiles,
  onConfirmation,
  generateTokenAPI,
  getCreditorDetailsByEmail,
  getCreditorDetailsByEmailId,
  getCreditorDetailsByClaimIdIdProof,
  createCreditorSaveAsDraft,
  holdinfo,
  holdsesinfo,
  createLoginLog
} from "../../APIFolder/api";
import encryption from "../../Utlitiy/encryption";
import { useLocation } from "react-router-dom";
import FormCA from "./FormCA";
import FormB from "./FormB";
import FormC from "./FormC";
import FormD from "./FormD";
import FormF from "./FormF";

import { jsPDF } from "jspdf";
import printJS from "print-js";
import notification from "../../Utlitiy/notification";
import StopScreen from "../StopScreen/stopScreen"
import { toast } from "react-toastify";

//  this is the middleComponent
const UploadScreen = (props) => {
  const location = useLocation();
  //  this is the function to change the screen
  const { nextScreen,previousScreen, creditorDetails, fileUploadingObject } = props.methods;
  var { updateCreditorDetails, creditor } = creditorDetails;

  var formtype = creditorDetails.creditor.c_obj.creditor_claim;
  formtype = formtype.toUpperCase();
  creditorDetails.creditor.c_obj.creditor_claim = formtype;

  useEffect(() => {
    if (
      creditorDetails.creditor.c_id !== "" &&
      creditorDetails.creditor.u_status === true
    ) {
      //  this is the function which will update the files that we have uploaded to our database
      const obj = { creditorDetails, nextScreen };
      updateUploadingFiles(obj);
      //  have to update the flag again so that files should be uploaded next time
      const { updateCreditorDetails } = creditorDetails;
      const { creditor } = creditorDetails;
      creditor.u_status = false;
      updateCreditorDetails(creditor);
    }
  }, [creditorDetails.creditor.c_id, creditorDetails.creditor.u_status]);
  //  in this state we store our fields values
  const [secondScreen, setSecondScreen] = useState({});
  const [dropDownValue, SetDropDownValue] = useState("Select");
  const [dropDownPanValue, SetDropDownPan] = useState("Select");

  //  this array is dedicated for uploaded forms and I think we have only one form
  //  this is my assumption please be carefull.

  const [uploadedForms, setUploadedForms] = useState([]);

  //  this array is for the attachments with the form at the time of uploading the form
  const [formAttachments, setformAttachments] = useState([]);

  //  this array is for the attachments with the form at the time of uploading the form
  const [formUploadDeclataion, setformUploadDeclataion] = useState([]);

  //  this array is for the attachments with the form at the time of uploading the form
  const [formDeclataionIdentification, setformDeclataionIdentification] =
    useState([]);

  //  this array is for the attachments with the form at the time of uploading the form
  const [formBankStatement, setformBankStatement] = useState([]);

  //  this array is for the attachments with the form at the time of uploading the form
  const [formMemberOfCert, setformMemberOfCert] = useState([]);

  //  this array is for the attachments with the form at the time of uploading the form
  const [formProofInterest, setformProofInterest] = useState([]);

  // this state is dedicated to know whether we have to start validation process or not
  const [formValidationStatus, setFormValidationStatus] = useState(false);


  //  these are the ids of our form fields to avoid confustion
  const id = {
    creditor: "creditor",
    amount_claimed: "amount_claimed",
    form_name: "form_name",
    form_id: "file_claim",
    uploaded_form: "uploaded_forms",
    form_attachments: "form_attachments",
    form_identification_proof: "upload_identification_proof",
    form_upload_declaration: "upload_declaration",
    form_bank_account_stmt: "bank_account_statement",
    form_membership_cert: "membership_certificate",
    form_proof_of_interest: "proof_of_interest",
    form_type_uploaded: "form_uploaded",

    //added recent
    name_of_creditor: "name_of_creditor",
    identification_finacial_creditor: "identification_finacial_creditor",
    email_address_finacial_creditor: "email_address_finacial_creditor",
    doc_reference: "doc_reference",
    details_debt_occured: "details_debt_occured",
    details_mutual_credit_debt: "details_mutual_credit_debt",
    details_security_held: "details_security_held",
    details_bank_account: "details_bank_account",
    documents_attached: "documents_attached",
    insolvency_professional: "insolvency_professional",
    signature_financial_creditor: "signature_financial_creditor",
    name_in_block_letter: "name_in_block_letter",
    position_with_creditor: "position_with_creditor",
    address_person_signing: "address_person_signing",
    creditor_city: "creditor_city",

    from_name: "from_name",
    from_address: "from_address",
    identification_number: "identification_number",
    registration_number: "registration_number",
    memebership_expiry_date: "membership_expiry_date",

    dropdown_1_name:"dropdown_1_name",
    folio_no_name:"folio_no_name",
    scheme_type:"scheme_type",
    scheme_period:"scheme_period",
    maturity_date:"maturity_date",
    membership_number:"membership_number",
    identification_number_formb: "identification_number_formb",
    vendor_code: "vendor_code",
    employee_code: "employee_code",
    beneficary_name:"beneficary_name",
    reason_for_delay_filing:"reason_for_delay_filing"
  };

  let form_upload_idft = {
    upload_declaration: "No",
    upload_identification_proof: "No",
    bank_account_statement: "No",
    membership_certificate: "No",
    proof_of_interest: "No",
  };

  //  you have to remove this code this is just for testing purposes
  const fillDefaultValues = () => {
    //  c_obj means  this object will contain all the details related to  creditor
    const { c_obj } = creditorDetails.creditor;
    //  we have to remove this object this is just for testing purpose
    const defaultValues = {
      [id.creditor]: (singlecreditorobj?.registration_number ? singlecreditorobj?.creditor : ""),
      [id.amount_claimed]: (singlecreditorobj?.identification_finacial_creditor ? singlecreditorobj?.identification_finacial_creditor : ""),
      [id.form_name]: (singlecreditorobj?.form_name ? singlecreditorobj?.form_name : ""),
    };

    Object.keys(defaultValues).map((id_field) => {
      //   console.log("id_field------------>", id_field, defaultValues[id_field]);
      if(document.getElementById(id_field)){
        document.getElementById(id_field).value = defaultValues[id_field];
      }
    });
    setSecondScreen({ ...defaultValues, 'email_id': creditorDetails.creditor.c_obj.email_id });

  };

  const [decryptedObject, setDecryptedObject] = useState({});
  useEffect(() => {
    //  fetch encryptedUrl from url
    var encryptedUrl = location.search.split("=")[1];
    //  Decrypt
    setDecryptedObject(encryption.decrypt(encryptedUrl));
    fillDefaultValues();
    //  console.log("uploadScreen", creditorDetails);
  }, []);

  //  here we are using use effect to generate new token
  const [isAllowed, setIsAllowed] = useState(false)
  const startAllowing = () => {
    setIsAllowed(true)
  }
  const stopAllowing = () => {
    setIsAllowed(false)
  }

  useEffect(() => {
    if ("token" in decryptedObject) {
      const obj = { previousScreen,decryptedObject, setDecryptedObject, fileUploadingObject, startAllowing};
      generateTokenAPI(obj);
    }
  }, [decryptedObject]);

  //  this is the arrow function to handle any change happenend in any input
  //  its a generic function
  const handleChange = (e) => {
    var { name, value } = e.target;
    value = typeof value === "string" ? value.toLowerCase() : value;
    if(e.target.id=="txt_folio_number" || e.target.id=="txt_membership_number"){
      value = value.replaceAll(" ", "");
      e.target.value = value;
    } else if(e.target.id=="txt_membership_expiry_date" || e.target.id=="scheme_period") {
      var date = new Date(document.getElementById("txt_membership_expiry_date").value);
      date.setFullYear(date.getFullYear() + parseFloat(document.getElementById("scheme_period").value));
      let year = date.getFullYear();
      let month = (1 + date.getMonth()).toString().padStart(2, '0');
      let day = date.getDate().toString().padStart(2, '0');
      document.getElementById("txt_membership_maturity_date").value = month + '/' + day + '/' + year;
    } else if(e.target.id=="txt_folio_number" && e.target.value.length > 12) {
      e.target.value = e.target.value.substring(0, 12);
    } else if(e.target.id=="txt_bnk_account" && e.target.value.length > 18) {
      e.target.value = e.target.value.substring(0, 18);
    }
    setSecondScreen({ ...secondScreen, [name]: value });
  };

  function validatamtevent(event){
    var { name, value } = event.target;
    if(document.getElementById("creditor").getAttribute("formca")=="false"){
      var val2 = document.getElementById("amount_claimed").value;
      if(val2 == "" || parseFloat(val2) < 0){
        document.getElementById("amount_claimed").value = 0;
      }
    }
    validatamt(event.target.id);
    try{
      //if(document.getElementById("creditor").getAttribute("formca")=="false"){
        var val1 = document.getElementById("creditor").value;
        var val2 = document.getElementById("amount_claimed").value;
        if(val2 == "" || parseFloat(val2) < 0){
          val2 = 0;
        }
        if(document.getElementById("span_amount_form")) document.getElementById("span_amount_form").innerHTML=parseFloat(val1)+parseFloat(val2);
        if(document.getElementById("span_from_insert_claim_amount")) {
          document.getElementById("span_from_insert_claim_amount").innerHTML=parseFloat(val1)+parseFloat(val2);
        }
      //} 

    }catch(error){}
    setSecondScreen({ ...secondScreen, [name]: value });
  }

  function validatamt(id){
    var obj = document.getElementById(id);
    var ret = true;
    var val = obj.value.trim();
    if(document.getElementById("creditor").getAttribute("formca")=="false" && id=="amount_claimed"){

    } else if(val.trim().length==0 || parseFloat(val+"")<1){
      obj.classList.add("style_error__3V2hp");
      ret = false;
      if(id=="form_name"){
        notification.validsurrendervalue();
      } else {
        notification.validAmount();
      }
    } else {
      obj.classList.remove("style_error__3V2hp");
    }
    return ret;
  }

  function validatExpiryDateEvent(e){
    var { name, value } = e.target;
    setSecondScreen({ ...secondScreen, [name]: value });
    validatExpiryDate();
  }

  function validatExpiryDate(){
    var ret = true;
    var val = document.getElementById("txt_membership_expiry_date").value.trim();
    if(val.length > 0){
      var sp = new Date(val);
      if(sp < new Date('1997-01-01') || sp > new Date('2030-12-31')){
        document.getElementById("txt_membership_expiry_date").classList.add("style_error__3V2hp");
        notification.validExpiryDate();
        ret = false;
      } else {
        document.getElementById("txt_membership_expiry_date").classList.remove("style_error__3V2hp");
      }
    }
    return ret;
  }

  const [singlecreditorobj, setSinglecreditorobj] = useState(null)
  function identificationnochange(event) {
    var drpdown1val = document.getElementById("dropdown_1").value;
    var { name, value } = event.target;
    value = typeof value === "string" ? value.toLowerCase() : value;

    if(drpdown1val != "Voter Id"){
      event.target.value=event.target.value.replace(/[`~!@#$%^&*()_|+\-=?;:'", .<>\{\}\[\]\\\/]/gi, '')
    }
    event.target.value=event.target.value.toUpperCase();
    if (event.target.value != "") {
      document.getElementById("lbl_identification_doc").innerHTML = event.target.value;
    } else {
      document.getElementById("lbl_identification_doc").innerHTML = "";
    }
    setSecondScreen({ ...secondScreen, [name]: value });
  }

  function identificationnoblur(event) {
    elementBlur();
    identificationnovalidationtext();
  }

  function identificationnovalidationtext() {
    var drpdown1val = document.getElementById("dropdown_1").value;
    var identificationumber = validateIdentificationNo();
    if(identificationumber=="") {
      if(drpdown1val == ""){
        notification.selectidentificationtype();
        document.getElementById("dropdown_1").classList.add("style_error__3V2hp");
      } else if(identificationumber=="") {
        if(drpdown1val == "Adhaar") notification.validAdhaarNumber();
        else if(drpdown1val == "PAN") notification.validPanNumber();
        else if(drpdown1val == "Passport") notification.validPassportNumber();
        else if(drpdown1val == "Voter Id") notification.validVoterIDCard();
      }
      document.getElementById("txt_identification_number").classList.add("style_error__3V2hp");
    }
  }

  useEffect(() => {
    if (isAllowed) {
      var identification_no = creditorDetails.creditor.c_obj.identification_no
      var claim_no = creditorDetails.creditor.c_obj.claim_no
      var claim_email_no = creditorDetails.creditor.c_obj.claim_email_no
      var phone_no = creditorDetails.creditor.c_obj.phone_no

      var email_id = creditorDetails.creditor.c_obj.email_id
      var form_name = creditorDetails.creditor.c_obj.creditor_claim

      if(form_name.toLowerCase()!="form ca" && form_name!="FORMCA"){
        //document.getElementById("row_idf_formca").style.display="none";
        document.getElementById("dropdown_1").value="PAN";
        document.getElementById("txt_identification_number").value="";

        document.getElementById("row_folio_number").style.display="none";
        document.getElementById("txt_folio_number").value = "1";
        document.getElementById("txt_membership_number").value = "1";

        document.getElementById("span_deposit_amount").innerHTML = "Amount";
        document.getElementById("span_amturity_amount").innerHTML = "Interest Amount(If Any)";
        document.getElementById("span_amturity_amount_red").style.display="none";
        document.getElementById("creditor").setAttribute("formca", "false");

        if(form_name.toLowerCase() === "form c" || form_name=="FORMC"){
          document.getElementById("creditor").setAttribute("formc", "true");
          document.getElementById("span_idf_proof_number").innerHTML = "Identification number of the financial creditor (If an incorporated body, provide identification number and proof of incorporation.If a partnership or individual provide identification records* of all the partners or the individual)";
        } else if(form_name.toLowerCase() === "form d" || form_name=="FORMD"){
          document.getElementById("span_idf_proof_number").innerHTML = "PAN NUMBER, PASSPORT, THE IDENTITY CARD ISSUED BY THE ELECTION COMMISSION OF INDIA OR AADHAAR CARD OF WORKMAN / EMPLOYEE";
        } else if(form_name.toLowerCase() === "form f" || form_name=="FORMF"){
          document.getElementById("span_idf_proof_number").innerHTML = "Identification number of the creditor (If an incorporated body corporate, provide identification number and proof of incorporation. If a partnership or individual, provide identification record* of all partners or the individuals)";
        } else if(form_name.toLowerCase() === "form b" || form_name=="FORMB"){
          document.getElementById("span_idf_proof_number").innerHTML = "IDENTIFICATION NUMBER OF OPERATIONAL CREDITOR (IF AN INCORPORATED BODY PROVIDE IDENTIFICATION NUMBER AND PROOF OF INCORPORATION. IF A PARTNERSHIP OR INDIVIDUAL PROVIDE IDENTIFICATION RECORDS* OF ALL THE PARTNERS OR THE INDIVIDUAL)";
        }

        document.getElementById("row_scheme").style.display="none";
        document.getElementById("row_membership_value_exp_date_surrender_val").style.display="none";
        document.getElementById("txt_membership_expiry_date").value = "2023-01-01";
        document.getElementById("txt_membership_maturity_date").value = "01/01/2023";
        
        //document.getElementById("row_idf_not_formca").style.display="";        

        document.getElementById("row_file_upload_membership_cert").style.display="none";
      } 
      if(form_name.toLowerCase()=="form b" || form_name=="FORMB"){
        document.getElementById("row_vendor_formb").style.display="";
      }
      if(form_name.toLowerCase()=="form d" || form_name=="FORMD" || 
          form_name.toLowerCase()=="form e" || form_name=="FORME"){
        document.getElementById("row_employee_code_formd_forme").style.display="";
      }

      if(claim_no != undefined && claim_no.length > 0){
        if((identification_no != undefined && identification_no.length > 0)
            || (claim_email_no != undefined && claim_email_no.length > 0)
            || (phone_no != undefined && phone_no.length > 0)){
          var id = claim_no+"";
          id = id.replace("FEL_CA_", "");
          var regno = identification_no==undefined?"":identification_no;
          var email_no = claim_email_no==undefined?"":claim_email_no;
          var phone_no = phone_no==undefined?"":phone_no;

          var body = {_id:id, registration_number:regno,email_id:email_no, phone_number:phone_no, setSinglecreditorobj, fileUploadingObject};
          getCreditorDetailsByClaimIdIdProof(body);
        }
      } else if(email_id != undefined && email_id.length > 0){
        form_name = form_name.toUpperCase();
        creditorDetails.creditor.c_obj.creditor_claim = form_name;
        var body = { email_id: email_id, form_name: form_name, setSinglecreditorobj, fileUploadingObject };
        localStorage.setItem("resoluteemail", email_id);

        /*if(form_name.toLowerCase() != "form ca"){
          document.getElementById("row_membership_value_exp_date_surrender_val").remove();
          document.getElementById("row_membership_value").remove();
          document.getElementById("txt_folio_number").remove();
          document.getElementById("row_file_upload_proof_interest").remove();
          document.getElementById("row_file_upload_membership_cert").remove();
          document.getElementById("row_file_upload_bank").remove();
          document.getElementById("row_folio_number").remove();
        }*/

        if(form_name.toLowerCase() === "form ca" || form_name=="FORMCA"){
          document.getElementById("formB").remove();
          document.getElementById("formC").remove();
          document.getElementById("formD").remove();
          document.getElementById("formF").remove();
        } else if(form_name.toLowerCase() === "form b" || form_name=="FORMB"){
          document.getElementById("formCA").remove();
          document.getElementById("formC").remove();
          document.getElementById("formD").remove();
          document.getElementById("formF").remove();
        } else if(form_name.toLowerCase() === "form c" || form_name=="FORMC"){
          document.getElementById("formCA").remove();
          document.getElementById("formB").remove();
          document.getElementById("formD").remove();
          document.getElementById("formF").remove();
        } else if(form_name.toLowerCase() === "form d" || form_name=="FORMD"){
          document.getElementById("formCA").remove();
          document.getElementById("formB").remove();
          document.getElementById("formC").remove();
          document.getElementById("formF").remove();
        } else if(form_name.toLowerCase() === "form f" || form_name=="FORMF"){
          document.getElementById("formCA").remove();
          document.getElementById("formB").remove();
          document.getElementById("formC").remove();
          document.getElementById("formD").remove();
        }

        var date = new Date();
        document.getElementById("lbl_form_date").innerHTML = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();

        getCreditorDetailsByEmailId(body);
      }
    }
  }, [isAllowed]);

  useEffect(() => {
    var claim_no = creditorDetails.creditor.c_obj.claim_no
    document.getElementById("p_edit_claim_note").style.display="none";
    if(claim_no!=undefined && claim_no!=null && claim_no.length>0){
      if(singlecreditorobj==""){
        notification.claimNotFound();
        setTimeout(function(){
          window.location.reload();
        }, 2000);
      }
    }
    if (singlecreditorobj) {
      var downloadprint = singlecreditorobj?.form_downloaded
      var formdownloaded = singlecreditorobj?.form_uploaded
      var edit_done = singlecreditorobj?.edit_done

      if(claim_no!=undefined && claim_no!=null && claim_no.length>0){
        document.getElementById(id.form_id).style.display="none";
        document.getElementById("form_claim_status").style.display="none";
        document.getElementById("form_view_doc").style.display="none";
        if(creditorDetails.creditor.claim_verify_type == 1){
          document.getElementById(id.form_id).style.display="";
          document.getElementById("p_edit_claim_note").style.display="";
        } else if(creditorDetails.creditor.claim_verify_type == 2){
          document.getElementById("form_claim_status").style.display="";

          document.getElementById("txt_clmsts_claim_id").value=claim_no;
          if(singlecreditorobj?.updatedAt!=undefined && singlecreditorobj?.updatedAt!=null){
            var claimdt = new Date(singlecreditorobj?.updatedAt+"").toLocaleString(undefined, {timeZone: 'Asia/Kolkata'});;
            document.getElementById("txt_clmsts_claim_filed_date").value=claimdt;
          }

          if(singlecreditorobj?.name_of_creditor!=undefined && singlecreditorobj?.name_of_creditor!=null){
            document.getElementById("txt_clmsts_claimant_name").value=singlecreditorobj?.name_of_creditor;
          }

          if(singlecreditorobj?.registration_number!=undefined && singlecreditorobj?.registration_number!=null){
            document.getElementById("txt_clmsts_identification_no").value=singlecreditorobj?.registration_number;
          }

          if(singlecreditorobj?.amount_claimed!=undefined && singlecreditorobj?.amount_claimed!=null){
            document.getElementById("txt_clmsts_amount_claimed").value=singlecreditorobj?.amount_claimed;
          }

          if(singlecreditorobj?.amount_admitted!=undefined && singlecreditorobj?.amount_admitted!=null){
            document.getElementById("txt_clmsts_amount_admitted").value=singlecreditorobj?.amount_admitted;
          }

          if(singlecreditorobj?.amount_under_verify!=undefined && singlecreditorobj?.amount_under_verify!=null){
            document.getElementById("txt_clmsts_amount_under_verify").value=singlecreditorobj?.amount_under_verify;
          }

          if(singlecreditorobj?.amount_not_admitted!=undefined && singlecreditorobj?.amount_not_admitted!=null){
            document.getElementById("txt_clmsts_amount_not_admitted").value=singlecreditorobj?.amount_not_admitted;
          }

          if(singlecreditorobj?.related_corporate_debtor!=undefined && singlecreditorobj?.related_corporate_debtor!=null){
            document.getElementById("txt_clmsts_related_party").value=singlecreditorobj?.related_corporate_debtor;
          }

          if(singlecreditorobj?.voter_percentage!=undefined && singlecreditorobj?.voter_percentage!=null){
            document.getElementById("txt_clmsts_coc_voter_percentage").value=singlecreditorobj?.voter_percentage;
          }

          if(singlecreditorobj?.remark!=undefined && singlecreditorobj?.remark!=null){
            document.getElementById("txt_clmsts_remark").value=singlecreditorobj?.remark;
          }

          if(singlecreditorobj?.edit_done!=undefined && singlecreditorobj?.edit_done!=null){
            if(singlecreditorobj?.edit_done == "yes")
            {
              document.getElementById("txt_modify_status").value="You have already modified your claim";
            }
            else
            {
              document.getElementById("txt_modify_status").value="You have not modified your claim yet";
            }
          }
        } else if(creditorDetails.creditor.claim_verify_type == 3){
          document.getElementById("form_view_doc").style.display="";
          //call the login log
          createLoginLog(creditorDetails);
        }

        var form_name = singlecreditorobj?.creditor_claim
        if(form_name!=undefined && form_name!=null && form_name.length>0){
          if(form_name.toLowerCase() === "form ca"){
            document.getElementById("formB").remove();
            document.getElementById("formC").remove();
            document.getElementById("formD").remove();
            document.getElementById("formF").remove();
          } else if(form_name.toLowerCase() === "form b"){
            document.getElementById("formCA").remove();
            document.getElementById("formC").remove();
            document.getElementById("formD").remove();
            document.getElementById("formF").remove();
          } else if(form_name.toLowerCase() === "form c"){
            document.getElementById("formCA").remove();
            document.getElementById("formB").remove();
            document.getElementById("formD").remove();
            document.getElementById("formF").remove();
          } else if(form_name.toLowerCase() === "form d"){
            document.getElementById("formCA").remove();
            document.getElementById("formB").remove();
            document.getElementById("formC").remove();
            document.getElementById("formF").remove();
          } else if(form_name.toLowerCase() === "form f"){
            document.getElementById("formCA").remove();
            document.getElementById("formB").remove();
            document.getElementById("formC").remove();
            document.getElementById("formD").remove();
          }
        }
      }

      var isEditable = true;
      if((downloadprint!=undefined && downloadprint!=null && downloadprint=="yes") ||
          (formdownloaded!=undefined && formdownloaded!=null && (formdownloaded=="yes" || formdownloaded=="Yes"))){
        isEditable = false;
      }

      if(!isEditable && claim_no!=undefined && claim_no!=null && claim_no.length>0){
        document.getElementById("row_form_upload_mandatory_sign").style.display="none";
        if(edit_done!="yes"){
          isEditable = true;
          downloadprint = singlecreditorobj?.edit_done;
          formdownloaded = "no";

          if(downloadprint=="downloaded"){
            document.getElementById("btn_saveasdraft").style.display="none";
            downloadprint = "yes";
            isEditable = false;
          }

          document.getElementById("row_file_upload_bank").style.display="none";
          //document.getElementById("row_file_identification").style.display="none";
          document.getElementById("row_file_upload_proof_interest").style.display="none";
          //document.getElementById("row_file_other_attachments").style.display="none";
          document.getElementById("dropdown_1").setAttribute("disabled", true);
          document.getElementById("dropdown-11").setAttribute("disabled", "true");
          //document.getElementById("txt_identification_number").setAttribute("disabled", true);
          creditorDetails.creditor.c_obj["email_id"]=singlecreditorobj?.email_address_finacial_creditor
        }
      }

      if(!isEditable){
        document.getElementById("btn_upload_claim").style.display="";
        document.getElementById("btn_saveasdraft").style.display="none";

        document.querySelectorAll('.txtallclasses').forEach(function (el) {
          el.classList.add("displaynone");
        });
        document.querySelectorAll('.custselectlist').forEach(function (el) {
          el.classList.add("displaynone");
        });
        document.querySelectorAll('.lblallclasses').forEach(function (el) {
          el.classList.remove("displaynone");
        });

        if(document.getElementById("txt_folio_number")){
          document.getElementById("txt_folio_number").setAttribute("disabled", "true");
        }

        document.getElementById("txt_identification_number_fb").setAttribute("disabled", "true");
        document.getElementById("txt_identification_number").setAttribute("disabled", "true");
        document.getElementById("creditor").setAttribute("disabled", "true");
        document.getElementById("amount_claimed").setAttribute("disabled", "true");
        document.getElementById("beneficary_name").setAttribute("disabled", "true");

        if(document.getElementById("form_name")){
          document.getElementById("form_name").setAttribute("disabled", "true");
        }
        document.getElementById("dropdown_1").setAttribute("disabled", "true");
        if(document.getElementById("txt_membership_expiry_date")){
          document.getElementById("txt_membership_expiry_date").setAttribute("disabled", "true");
        }
        if(document.getElementById("txt_membership_number")){
          document.getElementById("txt_membership_number").setAttribute("disabled", "true");
        }
        if(document.getElementById("scheme_type")){
          document.getElementById("scheme_type").setAttribute("disabled", "true");
        }
        if(document.getElementById("scheme_period")){
          document.getElementById("scheme_period").setAttribute("disabled", "true");
        }
        if(document.getElementById("txt_bnk_name")){
          document.getElementById("txt_bnk_name").setAttribute("disabled", "true");
        }
        if(document.getElementById("txt_ifsc_code")){
          document.getElementById("txt_ifsc_code").setAttribute("disabled", "true");
        }
        if(document.getElementById("txt_bnk_account")){
          document.getElementById("txt_bnk_account").setAttribute("disabled", "true");
        }
        if(document.getElementById("txt_vendor_code")){
          document.getElementById("txt_vendor_code").setAttribute("disabled", "true");
        }
        if(document.getElementById("txt_employee_code")){
          document.getElementById("txt_employee_code").setAttribute("disabled", "true");
        }
        if(document.getElementById("txt_reason_for_delay_filing")){
          document.getElementById("txt_reason_for_delay_filing").setAttribute("disabled", "true");
        }
      }

      if(formdownloaded != undefined && (formdownloaded=="yes" || formdownloaded=="Yes")) {
        document.getElementById("btn_upload_claim").remove();
        document.getElementById("btn_downloadprint").remove();
      }

      if(singlecreditorobj?.beneficary_name) document.getElementById("beneficary_name").value = singlecreditorobj?.beneficary_name;

      if(document.getElementById("txt_folio_number") && singlecreditorobj?.folio_number) {
        document.getElementById("txt_folio_number").value = singlecreditorobj?.folio_number;
      }
      if(document.getElementById("txt_membership_number") && singlecreditorobj?.membership_number) {
        document.getElementById("txt_membership_number").value = singlecreditorobj?.membership_number;
      }

      document.getElementById("txt_identification_number").value = singlecreditorobj?.registration_number;
      if(document.getElementById("creditor").getAttribute("formca") == "false"){
        console.log("25555");
        if(singlecreditorobj?.idf_not_formca) document.getElementById("lbl_identification_doc").innerHTML = singlecreditorobj?.idf_not_formca;
        if(document.getElementById("creditor").getAttribute("formc") != "true"){
          var amtspn = 0;
          if(singlecreditorobj?.creditor && singlecreditorobj?.creditor != ""){
            amtspn = parseFloat(singlecreditorobj?.creditor);
          }
          if(singlecreditorobj?.amount_claimed && singlecreditorobj?.amount_claimed != ""){
            amtspn = amtspn + parseFloat(singlecreditorobj?.amount_claimed);
          }
          document.getElementById("span_amount_form").innerHTML = amtspn;
        }
      } else {
        console.log("26666");
        document.getElementById("lbl_identification_doc").innerHTML = singlecreditorobj?.registration_number;
      }
      if(document.getElementById("txt_identification_number").disabled == false){
        document.getElementById("txt_identification_number").value=document.getElementById("txt_identification_number").value.replace(/[`~!@#$%^&*()_|+\-=?;:'", .<>\{\}\[\]\\\/]/gi, '')
      }
      SetDropDownValue(singlecreditorobj?.identification_type);
      document.getElementById("txt_identification_number_fb").value = singlecreditorobj?.idf_not_formca;

      if(document.getElementById("txt_identification_number").disabled==false &&
          document.getElementById("txt_identification_number").value.trim().length > 0){
        document.getElementById("dropdown_1").value = singlecreditorobj?.identification_type;
        identificationnovalidationtext();
      }

      try{
        if (singlecreditorobj?.membership_expiry_date) {
          var expiry_date = singlecreditorobj?.membership_expiry_date;
          var resdate = expiry_date.split("/")[2] + "-" + (expiry_date.split("/")[1].length == 1 ? "0" + expiry_date.split("/")[1] : expiry_date.split("/")[1])
            + "-" + (expiry_date.split("/")[0].length == 1 ? "0" + expiry_date.split("/")[0] : expiry_date.split("/")[0]);
          document.getElementById("txt_membership_expiry_date").value = resdate;
        }
      }catch(error){

      }

      if(document.getElementById("txt_membership_expiry_date").disabled==false &&
          document.getElementById("txt_membership_expiry_date").value.length>0){
        validatExpiryDate();
      }

      if(singlecreditorobj?.dispute_details && document.getElementById("txt_dispute_details")){
        document.getElementById("txt_dispute_details").value = singlecreditorobj?.dispute_details;
      }

      document.getElementById("scheme_type").value = singlecreditorobj?.scheme_type;
      document.getElementById("scheme_period").value = singlecreditorobj?.scheme_period;
      document.getElementById("txt_membership_maturity_date").value = singlecreditorobj?.maturity_date;
      var bnkdetail = singlecreditorobj?.details_bank_account;
      if(bnkdetail != undefined && bnkdetail != ""){
        bnkdetail = bnkdetail.split(",");
        document.getElementById("txt_bnk_name").value=bnkdetail[0];
        document.getElementById("txt_ifsc_code").value=bnkdetail[1];
        document.getElementById("txt_bnk_account").value=bnkdetail[2];
      }

      document.getElementById("txt_vendor_code").value=singlecreditorobj?.vendor_code;
      document.getElementById("txt_reason_for_delay_filing").value=singlecreditorobj?.reason_for_delay_filing;
      document.getElementById("txt_employee_code").value=singlecreditorobj?.employee_code;

      document.getElementById("dropdown_1").value = singlecreditorobj?.identification_type;
      creditorDetails.creditor.c_obj.claim_no_abcd = singlecreditorobj?._id;

      var form_name = creditorDetails.creditor.c_obj.creditor_claim
      var formdownloaded = singlecreditorobj?.form_uploaded
      if(formdownloaded.toLowerCase()=="yes"){
        form_name = form_name.replace("FORM", "");
        console.log("form_name : "+form_name);
        document.getElementById("row_idf_claimno").style.display="";
        document.getElementById("txt_idf_claimno").value="KAR_"+form_name+"_"+creditorDetails.creditor.c_obj.claim_no_abcd;
      }
      fillDefaultValues();
    }
  }, [singlecreditorobj])

  function totalclaimamountchange(event) {
    if (event.target.value != "") {
      document.getElementById("lbl_identification_doc").innerHTML = event.target.value;
    } else {
      document.getElementById("lbl_identification_doc").innerHTML = "";
    }
  }

  const focusCreditorField = () => {
    var creditorField = document.getElementById(id.creditor);
    creditorField.value = "";
    creditorField.focus();
    const duplicateUser = { ...secondScreen };
    duplicateUser[id.creditor] = "";
    setSecondScreen(duplicateUser);
  };

  const removeDuplicateObjects = (d_files) => {
    var u_files_obj = {};
    //  we are taking filename as a key
    d_files.map((obj) => {
      u_files_obj[obj.fileName] = obj;
    });
    var u_files_array = [];
    Object.keys(u_files_obj).map((key) => {
      u_files_array.push(u_files_obj[key]);
    });
    return u_files_array;
  };

  const previewFormMethod = () => { };

  function validateIdentificationNo(){
    var identificationumber = document.getElementById("txt_identification_number").value;
    if(document.getElementById("txt_identification_number").disabled==false){
      if(document.getElementById("dropdown_1").value == "Adhaar"){
        //var regexp=/^[2-9]{1}[0-9]{3}\s{1}[0-9]{4}\s{1}[0-9]{4}$/;
        var regexp=/^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/;
        if(!regexp.test(identificationumber)) identificationumber = "";
      } else if(document.getElementById("dropdown_1").value == "PAN"){
        var regexp = /[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
        if(!regexp.test(identificationumber)) identificationumber = "";
      } else if(document.getElementById("dropdown_1").value == "Passport"){
        var regexp = /^[A-PR-WY][1-9]\d\s?\d{4}[1-9]$/g;
        if(!regexp.test(identificationumber)) identificationumber = "";
      } /*else if(document.getElementById("dropdown_1").value == "Voter Id"){
        var regexp = /^([a-zA-Z]){3}([0-9]){7}?$/g;
        if(!regexp.test(identificationumber)) identificationumber = "";
      }*/
    }

    return identificationumber;
  }

  function validateIdentificationNobool(){
    var ret = true;
    if(validateIdentificationNo() == "") ret = false;
    return ret;
  }

  function startDownloadZip(){
    if (window.confirm("By downloading the file you accept the terms and condition shown above. Do you want to proceed?") == true) {
      window.open("https://dcirrus.co.in/TestNew.zip", "_blank");
    }
  }

  //  this is the place where real validation happens
  const validate = () => {
    var proceed = true
    var claimnocredit = creditorDetails.creditor.c_obj.claim_no;
    if(claimnocredit!=undefined && claimnocredit!=null && claimnocredit.length>0){
        var msg = "";
        if(uploadedForms.length==0 && formMemberOfCert.length==0){
          msg = "You have not uploaded both signed form and fdr certificate.";
        } else if(uploadedForms.length==0){
          msg = "You have not uploaded signed form.";
        } else if(formMemberOfCert.length==0){
          msg = "You have not uploaded fdr certificate.";
        }

        msg += "Once you proceed you will not be able to edit the data. Do you wish to proceed? ";
        if (window.confirm(msg) == false) {
          proceed = false
        }
    }

    if(proceed){
      const {
        [id.creditor]: creditor_name,
        [id.amount_claimed]: amount_claimed,
        [id.form_name]: form_name,
      } = secondScreen;

      var creditoramountrange=true;
      var creditormty = parseFloat(document.getElementById("creditor").value);
      if(creditormty<1){
        creditoramountrange = false;
      }

      var dropdown11 = true;
      if(formtype.toLowerCase()=="form ca" && document.getElementById("dropdown-11").value=="Select"){
        dropdown11 = false;
      }

      var txt_dispute_details = true;
      if(formtype.toLowerCase()=="form b" && document.getElementById("txt_dispute_details").value==""){
        txt_dispute_details = false;
      }

      var txt_folio_number = true;
      if(formtype.toLowerCase()=="form ca" && document.getElementById("txt_folio_number").value==""){
        txt_folio_number = false;
      }

      var txt_details_security_held = true;
      if(document.getElementById("txt_details_security_held") &&
        document.getElementById("txt_details_security_held").value==""){
        txt_details_security_held = false;
      }

      var txt_bank_account = true;
      if(document.getElementById("txt_bank_account") &&
        document.getElementById("txt_bank_account").value==""){
          txt_bank_account = false;
      }

      var txt_doc_attach_list = true;
      if(document.getElementById("txt_doc_attach_list") &&
        document.getElementById("txt_doc_attach_list").value==""){
          txt_doc_attach_list = false;
      }

      var txt_doc_details = true;
      if(document.getElementById("txt_doc_details") &&
        document.getElementById("txt_doc_details").value==""){
          txt_doc_details = false;
      }

      var txt_debt_incurred = true;
      if(document.getElementById("txt_debt_incurred") &&
        document.getElementById("txt_debt_incurred").value==""){
          txt_debt_incurred = false;
      }

      var txt_mutual_credit = true;
      if(document.getElementById("txt_mutual_credit") &&
        document.getElementById("txt_mutual_credit").value==""){
          txt_mutual_credit = false;
      }

      var dropdown12 = true;
      if(document.getElementById("dropdown-12") && document.getElementById("dropdown-12").value=="Select"){
        dropdown12 = false;
      }

      var uploadformschecked=false;
      if(uploadedForms.length>0){
        uploadformschecked=true;
      } else if(claimnocredit!=undefined && claimnocredit!=null && claimnocredit.length>0){
          uploadformschecked=true;
      }

      var formDeclataionIdentificationchecked=false;
      if(formDeclataionIdentification.length>0){
        formDeclataionIdentificationchecked=true;
      } else if(claimnocredit!=undefined && claimnocredit!=null && claimnocredit.length>0){
          formDeclataionIdentificationchecked=true;
      }

      var formUploadDeclataionchecked=false;
      if(formUploadDeclataion.length>0){
        formUploadDeclataionchecked=true;
      } else {
        alert("Please upload bank proof attachments");
      }

      var formMemberOfCertchecked=false;
      if(formMemberOfCert.length>0 || document.getElementById("creditor").getAttribute("formca")=="false"){
        formMemberOfCertchecked=true;
      } else {
        alert("Please upload fdr certificate");
      }

      var identificationumber = validateIdentificationNo();
      var drpdown1val = document.getElementById("dropdown_1").value;
      var expirydatevalid = validatExpiryDate();

      var amoutclaimchecked = true;
      if(document.getElementById("creditor").getAttribute("formca")!="false" 
        && (amount_claimed < 1)){
          amoutclaimchecked = false; 
      }

      var txt_amount_formc = true;
      if(document.getElementById("creditor").getAttribute("formc")=="true") {
        if(document.getElementById("txt_amount_formc").value==""){
          alert("please enter form amount");
          txt_amount_formc = false;
        }
      }

      var txt_vendor_code = true;
      if(document.getElementById("creditor").getAttribute("formb")=="true"){
        if(document.getElementById("txt_vendor_code").value==""){
          alert("please enter vendor code");
          txt_vendor_code = false;
        }
      }

      var txt_employee_code = true;
      if(document.getElementById("creditor").getAttribute("formd")=="true" && 
          document.getElementById("txt_employee_code").value==""){
        alert("please enter employee code minimum of 9 characters");
        txt_employee_code = false;
      }

      var txt_employee_code_9 = true;
      if(document.getElementById("creditor").getAttribute("formd")=="true" && 
        document.getElementById("txt_employee_code").value.trim().length<9){
        alert("please enter employee code minimum of 9 characters");
        txt_employee_code_9 = false;
      }
  
      var beneficary_name = true;
      if(document.getElementById("beneficary_name").value.trim()==""){
        alert("please enter beneficiary name");
        beneficary_name = false;
      }

      if(document.getElementById("txt_reason_for_delay_filing").value.trim()==""){
        alert("please enter reason for delay filing");
        return;
      }

      if ( !creditoramountrange
        || !amoutclaimchecked
        || creditor_name < 1
        || (form_name != "" && (form_name < 1))
        || !uploadformschecked
        || !formDeclataionIdentificationchecked
        || !formUploadDeclataionchecked
        || !formMemberOfCertchecked
        || identificationumber == ""
        || !txt_amount_formc
        || document.getElementById("txt_from_name").value == ""
        || document.getElementById("txt_from_address").value == ""
        || !expirydatevalid
        || drpdown1val == ""
        || !txt_folio_number
        || !txt_doc_details
        || !txt_debt_incurred
        || !txt_mutual_credit
        || !txt_details_security_held
        || !txt_bank_account
        || !txt_doc_attach_list
        || !dropdown11
        || document.getElementById("txt_name_in_block_letter").value == ""
        || document.getElementById("txt_creditor_position").value == ""
        || document.getElementById("txt_address_signing").value == ""
        || !dropdown12
        || !txt_dispute_details
        || !txt_vendor_code
        || !txt_employee_code
        || !beneficary_name
        || !txt_employee_code_9
      ) {
        if(!creditoramountrange){
          notification.validAmountClaimed();
        } else if(drpdown1val == "Select"){
          notification.selectidentificationtype();
        } else if(identificationumber=="" && document.getElementById("txt_identification_number").disabled==false) {
          if(drpdown1val == "Adhaar") notification.validAdhaarNumber();
          else if(drpdown1val == "PAN") notification.validPanNumber();
          else if(drpdown1val == "Passport") notification.validPassportNumber();
          else if(drpdown1val == "Voter Id") notification.validVoterIDCard();
        } else if(!dropdown12) { 
          notification.validateselectrelateddebtor();
          document.getElementById("dropdown-12").focus();
        } else if(form_name != "" && (form_name < 1)) {
            notification.validsurrendervalue();
        } else if((amount_claimed!="" && amount_claimed < 1) || creditor_name < 1) {
          notification.validAmount();
        } else {
          notification.missingmandatoryfields();
        }
        setFormValidationStatus(true);
        return false;
      } else {
        //  now everything is alright
        //  fields are validated
        setFormValidationStatus(false);

        document.getElementById("txt_identification_number").classList.remove("style_error__3V2hp");

        const { creditor, updateCreditorDetails } = creditorDetails;
        const form_name = secondScreen[id.form_name];
        const amount_claimed = secondScreen[id.amount_claimed];
        const creditor_name = secondScreen[id.creditor];

        creditor.c_obj[id.creditor] = creditor_name;
        creditor.c_obj[id.amount_claimed] = amount_claimed;
        creditor.c_obj[id.form_name] = form_name;

        creditor.c_obj[id.form_upload_declaration] = form_upload_idft.upload_declaration;
        creditor.c_obj[id.form_identification_proof] = form_upload_idft.upload_identification_proof;
        creditor.c_obj[id.form_bank_account_stmt] = form_upload_idft.bank_account_statement;
        creditor.c_obj[id.form_membership_cert] = form_upload_idft.membership_certificate;
        creditor.c_obj[id.form_proof_of_interest] = form_upload_idft.proof_of_interest;

        creditor.c_obj[id.form_attachments] = "No";
        creditor.c_obj[id.form_type_uploaded] = "No";
        if (formAttachments.length > 0)
          creditor.c_obj[id.form_attachments] = "Yes";
        if (uploadedForms.length > 0)
          creditor.c_obj[id.form_type_uploaded] = "Yes";

        //addedd recent
        creditor.c_obj["creditor_claim"] = creditorDetails.creditor.c_obj.creditor_claim;

        creditor.c_obj[id.name_of_creditor] = document.getElementById("txt_from_name").value;
        creditor.c_obj[id.identification_finacial_creditor] = secondScreen[id.amount_claimed];

        creditor.c_obj[id.email_address_finacial_creditor] =
          creditorDetails.creditor.c_obj.email_id;

        if(document.getElementById("txt_doc_details")){
          creditor.c_obj[id.doc_reference] = document.getElementById("txt_doc_details").value;
        }
        if(document.getElementById("txt_debt_incurred")){
          creditor.c_obj[id.details_debt_occured] = document.getElementById("txt_debt_incurred").value;
        }
        if(document.getElementById("txt_mutual_credit")){
          creditor.c_obj[id.details_mutual_credit_debt] = document.getElementById("txt_mutual_credit").value;
        }
        if(document.getElementById("txt_details_security_held")){
          creditor.c_obj[id.details_security_held] = document.getElementById("txt_details_security_held").value;
        }
        if(document.getElementById("txt_bank_account")){
          creditor.c_obj[id.details_bank_account] = document.getElementById("txt_bank_account").value;
        }
        if(document.getElementById("txt_doc_attach_list")){
          creditor.c_obj[id.documents_attached] = document.getElementById("txt_doc_attach_list").value;
        }
        if(document.getElementById("dropdown-11")) {
          creditor.c_obj[id.insolvency_professional] = document.getElementById("dropdown-11").value;
        }
        if(document.getElementById("txt_vendor_code")) {
          creditor.c_obj[id.vendor_code] = document.getElementById("txt_vendor_code").value;
        }
        if(document.getElementById("txt_employee_code")) {
          creditor.c_obj[id.employee_code] = document.getElementById("txt_employee_code").value;
        }
        creditor.c_obj[id.signature_financial_creditor] = "";//document.getElementById("txt_mutual_credit").value;
        creditor.c_obj[id.name_in_block_letter] = document.getElementById("txt_name_in_block_letter").value;
        creditor.c_obj[id.position_with_creditor] = document.getElementById("txt_creditor_position").value;
        creditor.c_obj[id.address_person_signing] = document.getElementById("txt_address_signing").value;

        if(document.getElementById("txt_folio_number")){
          creditor.c_obj["folio_number"] = document.getElementById("txt_folio_number").value;
        }

        if(document.getElementById("txt_membership_number")) {
          creditor.c_obj["membership_number"] = document.getElementById("txt_membership_number").value;
        }
        creditor.c_obj[id.creditor_city] = secondScreen[id.creditor_city];

        creditor.c_obj[id.from_name] = document.getElementById("txt_from_name").value;
        creditor.c_obj[id.from_address] = document.getElementById("txt_from_address").value;
        creditor.c_obj[id.registration_number] = document.getElementById("txt_identification_number").value;
        creditor.c_obj[id.amount_claimed] = secondScreen[id.amount_claimed];
        creditor.c_obj[id.beneficary_name] = document.getElementById("beneficary_name").value;
        if(document.getElementById("txt_membership_expiry_date")) {
          var dt = new Date(document.getElementById("txt_membership_expiry_date").value);
          creditor.c_obj["membership_expiry_date"] = dt.getDate() + "/" + (dt.getMonth() + 1) + "/" + dt.getFullYear();
        }
        creditor.c_obj["identification_type"] = document.getElementById("dropdown_1").value;
        if(document.getElementById("dropdown-11")) {
          creditor.c_obj["insolvency_prof"] = document.getElementById("dropdown-11").value;
        }

        creditor.c_obj["scheme_type"] = document.getElementById("scheme_type").value;
        creditor.c_obj["scheme_period"] = document.getElementById("scheme_period").value;
        creditor.c_obj["maturity_date"] = document.getElementById("txt_membership_maturity_date").value;

        creditor.c_obj["form_uploaded"] = "no"
        creditor.c_obj["form_downloaded"] = "yes"
        if(document.getElementById("dropdown-12")){
          creditor.c_obj["related_corporate_debtor"] = document.getElementById("dropdown-12").value;
        }
        if(document.getElementById("txt_dispute_details")) {
          creditor.c_obj["dispute_details"] = document.getElementById("txt_dispute_details").value;
        }

        //creditor.c_obj["idf_not_formca"] = document.getElementById("txt_identification_number_fb").value;
        if(document.getElementById("txt_amount_formc") != null) {
          creditor.c_obj["amount_formc"] = document.getElementById("txt_amount_formc").value;
        }

        if(document.getElementById("txt_reason_for_delay_filing") != null) {
          creditor.c_obj["reason_for_delay_filing"] = document.getElementById("txt_reason_for_delay_filing").value;
        }

        creditor.f_obj.form_name = form_name;
        updateCreditorDetails(creditor);
        console.log("these are the information of creditor after validation",creditor);

        if(uploadedForms.length>0 && formDeclataionIdentification.length>0){
          uploadfilesmethod();
        } else if(claimnocredit!=undefined && claimnocredit!=null && claimnocredit.length>0){
          if(uploadedForms.length>0 || formMemberOfCert.length > 0){
            uploadfilesmethod();
          } else {
            nextScreen();
          }
        }
      }
    }
  };

  const uploadfilesmethod = () => {
    var d_files = [
      ...uploadedForms,
      ...formUploadDeclataion,
      ...formDeclataionIdentification,
      ...formBankStatement,
      ...formMemberOfCert,
      ...formProofInterest,
      ...formAttachments,
    ];

    creditor.f_obj.files = removeDuplicateObjects(d_files);
    const obj = {
      creditorDetails,
      decryptedObject,
      showModal,
      fileUploadingObject
    };
    createCreditorFolder(obj);
  };

  //  this is the place where real validation happens
  const validatesaveasdraft = () => {
    const {
      [id.creditor]: creditor_name,
      [id.amount_claimed]: amount_claimed,
      //  [id.amount_admitted]: amount_admitted,

      [id.form_name]: form_name,
    } = secondScreen;

    var creditoramountrange=true;
    var creditormty = parseFloat(document.getElementById("creditor").value);
    if(creditormty<1){
      creditoramountrange = false;
      console.log("error--------->"+1);
    }
    
    var dropdown11 = true;
    if(formtype.toLowerCase()=="form ca" && document.getElementById("dropdown-11").value=="Select"){
      dropdown11 = false;
      console.log("error--------->"+2);
    }

    var txt_dispute_details = true;
    if(formtype.toLowerCase()=="form b" && document.getElementById("txt_dispute_details").value==""){
      txt_dispute_details = false;
      console.log("error--------->"+3);
    }

    var txt_folio_number = true;
    if(formtype.toLowerCase()=="form ca" && document.getElementById("txt_folio_number").value==""){
      txt_folio_number = false;
      console.log("error--------->"+4);
    }

    var txt_details_security_held = true;
    if(document.getElementById("txt_details_security_held") &&
      document.getElementById("txt_details_security_held").value==""){
      txt_details_security_held = false;
      console.log("error--------->"+5);
    }

    var txt_bank_account = true;
    if(document.getElementById("txt_bank_account") &&
      document.getElementById("txt_bank_account").value==""){
        txt_bank_account = false;
        console.log("error--------->"+6);
    }

    var txt_doc_attach_list = true;
    if(document.getElementById("txt_doc_attach_list") &&
      document.getElementById("txt_doc_attach_list").value==""){
        txt_doc_attach_list = false;
    }

    var txt_doc_details = true;
    if(document.getElementById("txt_doc_details") &&
      document.getElementById("txt_doc_details").value==""){
        txt_doc_details = false;
        console.log("error--------->"+7);
    }

    var txt_debt_incurred = true;
    if(document.getElementById("txt_debt_incurred") &&
      document.getElementById("txt_debt_incurred").value==""){
        txt_debt_incurred = false;
        console.log("error--------->"+8);
    }

    var txt_mutual_credit = true;
    if(document.getElementById("txt_mutual_credit") &&
      document.getElementById("txt_mutual_credit").value==""){
        txt_mutual_credit = false;
        console.log("error--------->"+9);
    }

    var dropdown12 = true;
    if(document.getElementById("dropdown-12") && document.getElementById("dropdown-12").value=="Select"){
      dropdown12 = false;
      console.log("error--------->"+10);
    }

    var identificationumber = validateIdentificationNo();
    var drpdown1val = document.getElementById("dropdown_1").value;
    var expirydatevalid = validatExpiryDate();

    var txt_folio_number = true;
    if(document.getElementById("txt_folio_number") &&
      document.getElementById("txt_folio_number").value==""){
        txt_folio_number = false;
        console.log("error--------->"+11);
    }

    var txt_membership_number = true;
    if(document.getElementById("txt_membership_number") &&
      document.getElementById("txt_membership_number").value==""){
        txt_membership_number = false;
        console.log("error--------->"+12);
    }

    var txt_membership_expiry_date = true;
    if(document.getElementById("txt_membership_expiry_date") &&
      document.getElementById("txt_membership_expiry_date").value==""){
        txt_membership_expiry_date = false;
        console.log("error--------->"+13);
    }

    var txt_bnk_name = true;
    if(document.getElementById("txt_bnk_name") && document.getElementById("txt_bnk_name").value.trim()==""){
        alert("Please enter bank name for claim processing.");
        txt_bnk_name = false;
        console.log("error--------->"+14);
    }

    var txt_ifsc_code = true;
    if(document.getElementById("txt_ifsc_code") &&
      document.getElementById("txt_ifsc_code").value==""){
        txt_ifsc_code = false;
        console.log("error--------->"+15);
    }

    var txt_bnk_account = true;
    if(document.getElementById("txt_bnk_account") &&
      document.getElementById("txt_bnk_account").value==""){
        txt_bnk_account = false;
        console.log("error--------->"+16);
    }

    var amoutclaimchecked = true;
    if(document.getElementById("creditor").getAttribute("formca")!="false" 
      && (amount_claimed!= "" && amount_claimed < 1)){
        amoutclaimchecked = false; 
        console.log("error--------->"+17);
    }

    var txt_amount_formc = true;
    if(document.getElementById("creditor").getAttribute("formc")=="true"){
      if(document.getElementById("txt_amount_formc").value==""){
        alert("please enter form amount");
        txt_amount_formc = false;
        console.log("error--------->"+18);
      }
    }

    var form_name_check = creditorDetails.creditor.c_obj.creditor_claim;
    var txt_vendor_code = true;
    if(form_name_check.toLowerCase()=="formb"){
      if(document.getElementById("txt_vendor_code").value==""){
        alert("please enter vendor code");
        txt_vendor_code = false;
        console.log("error--------->"+19);
      }
    }

    var txt_employee_code = true;
    if(form_name_check.toLowerCase()=="formd" && document.getElementById("txt_employee_code").value==""){
      alert("please enter employee code minimum of 9 characters");
      txt_employee_code = false;
      console.log("error--------->"+20);
    }

    var txt_employee_code_9 = true;
    if(form_name_check.toLowerCase()=="formd" && document.getElementById("txt_employee_code").value.trim().length<9){
      alert("please enter employee code minimum of 9 characters");
      txt_employee_code_9 = false;
      console.log("error--------->"+21);
    }

    var beneficary_name = true;
    if(document.getElementById("beneficary_name").value.trim()==""){
      alert("please enter beneficiary name");
      beneficary_name = false;
      console.log("error--------->"+22);
    }

    if(document.getElementById("txt_reason_for_delay_filing").value.trim()==""){
      alert("please enter reason for delay filing");
      return;
    }

    console.log("dropdown12 : " + dropdown12);
    if ( !creditoramountrange
      || !amoutclaimchecked
      || creditor_name < 1
      || (form_name != "" && (form_name < 1))
      || identificationumber == ""
      || !txt_amount_formc
      || !txt_folio_number
      || document.getElementById("txt_from_name").value == ""
      || document.getElementById("txt_from_address").value == ""
      || drpdown1val == ""
      || !expirydatevalid
      || !txt_folio_number
      || !txt_doc_details
      || !txt_debt_incurred
      || !txt_mutual_credit
      || !txt_details_security_held
      || !txt_bank_account
      || !txt_doc_attach_list
      || !dropdown11
      || document.getElementById("txt_name_in_block_letter").value == ""
      || document.getElementById("txt_creditor_position").value == ""
      || document.getElementById("txt_address_signing").value == ""
      || !dropdown12
      || !txt_dispute_details
      || !txt_folio_number
      || !txt_membership_number
      || !txt_membership_expiry_date
      || !txt_bnk_name
      || !txt_ifsc_code
      || !txt_bnk_account
      || !txt_vendor_code
      || !txt_employee_code
      || !beneficary_name
      || !txt_employee_code_9
    ) {
      console.log("creditoramountrange 1 : " + creditoramountrange + 
      " :: amount_claimed : " + amount_claimed + " :: creditor_name : " + creditor_name);
      if(!creditoramountrange){
        notification.validAmountClaimed();
      } else if(drpdown1val == "Select"){
        notification.selectidentificationtype();
      } else if(identificationumber=="" && document.getElementById("txt_identification_number").disabled==false) {
        if(drpdown1val == "Adhaar") notification.validAdhaarNumber();
        else if(drpdown1val == "PAN") notification.validPanNumber();
        else if(drpdown1val == "Passport") notification.validPassportNumber();
        else if(drpdown1val == "Voter Id") notification.validVoterIDCard();
      } else if(form_name != "" && (form_name < 1)) {
          notification.validsurrendervalue();
      } else if(!dropdown12) { 
        notification.validateselectrelateddebtor();
        document.getElementById("dropdown-12").focus();
      } else if((amount_claimed!="" && amount_claimed < 1) || creditor_name < 1) {
        notification.validAmount();
      } else {
        notification.missingmandatoryfields();
      }
      setFormValidationStatus(true);
      return false;
    } else {
      //  now everything is alright
      //  fields are validated
      setFormValidationStatus(false);
      document.getElementById("txt_identification_number").classList.remove("style_error__3V2hp");

      const { creditor, updateCreditorDetails } = creditorDetails;

      var claim_no = creditor.c_obj.claim_no
      const form_name = secondScreen[id.form_name];
      const amount_claimed = secondScreen[id.amount_claimed];
      const creditor_name = secondScreen[id.creditor];

      creditor.c_obj[id.creditor] = creditor_name;
      creditor.c_obj[id.amount_claimed] = amount_claimed;
      creditor.c_obj[id.form_name] = form_name;

      creditor.c_obj[id.form_upload_declaration] =
        form_upload_idft.upload_declaration;
      creditor.c_obj[id.form_identification_proof] =
        form_upload_idft.upload_identification_proof;
      creditor.c_obj[id.form_bank_account_stmt] =
        form_upload_idft.bank_account_statement;
      creditor.c_obj[id.form_membership_cert] =
        form_upload_idft.membership_certificate;
      creditor.c_obj[id.form_proof_of_interest] =
        form_upload_idft.proof_of_interest;

      if(claim_no==undefined || claim_no==null || claim_no.length==0){
        creditor.c_obj[id.form_attachments] = "No";
        creditor.c_obj[id.form_type_uploaded] = "No";
        if (formAttachments.length > 0) creditor.c_obj[id.form_attachments] = "Yes";
        if (uploadedForms.length > 0) creditor.c_obj[id.form_type_uploaded] = "Yes";
      }

      //addedd recent
      creditor.c_obj["creditor_claim"] = creditorDetails.creditor.c_obj.creditor_claim;

      creditor.c_obj[id.name_of_creditor] = document.getElementById("txt_from_name").value;
      creditor.c_obj[id.identification_finacial_creditor] = secondScreen[id.amount_claimed];

      creditor.c_obj[id.email_address_finacial_creditor] = creditorDetails.creditor.c_obj.email_id;

      if(document.getElementById("txt_doc_details")){
        creditor.c_obj[id.doc_reference] = document.getElementById("txt_doc_details").value;
      }
      if(document.getElementById("txt_debt_incurred")){
        creditor.c_obj[id.details_debt_occured] = document.getElementById("txt_debt_incurred").value;
      }
      if(document.getElementById("txt_mutual_credit")){
        creditor.c_obj[id.details_mutual_credit_debt] = document.getElementById("txt_mutual_credit").value;
      }
      if(document.getElementById("txt_details_security_held")){
        creditor.c_obj[id.details_security_held] = document.getElementById("txt_details_security_held").value;
      }
      if(document.getElementById("txt_bank_account")){
        creditor.c_obj[id.details_bank_account] = document.getElementById("txt_bank_account").value;
      }
      if(document.getElementById("txt_doc_attach_list")){
        creditor.c_obj[id.documents_attached] = document.getElementById("txt_doc_attach_list").value;
      }
      if(document.getElementById("dropdown-11")){
        creditor.c_obj[id.insolvency_professional] = document.getElementById("dropdown-11").value;
      }
      if(document.getElementById("txt_vendor_code")){
        creditor.c_obj[id.vendor_code] = document.getElementById("txt_vendor_code").value;
      }
      if(document.getElementById("txt_employee_code")){
        creditor.c_obj[id.employee_code] = document.getElementById("txt_employee_code").value;
      }
      creditor.c_obj[id.signature_financial_creditor] = "";//document.getElementById("txt_mutual_credit").value;
      creditor.c_obj[id.name_in_block_letter] = document.getElementById("txt_name_in_block_letter").value;
      creditor.c_obj[id.position_with_creditor] = document.getElementById("txt_creditor_position").value;
      creditor.c_obj[id.address_person_signing] = document.getElementById("txt_address_signing").value;
      creditor.c_obj[id.beneficary_name] = document.getElementById("beneficary_name").value;

      if(document.getElementById("txt_folio_number")){
        creditor.c_obj["folio_number"] = document.getElementById("txt_folio_number").value;
      }
      if(document.getElementById("txt_membership_number")){
        creditor.c_obj["membership_number"] = document.getElementById("txt_membership_number").value;
      }
      creditor.c_obj[id.creditor_city] = secondScreen[id.creditor_city];

      creditor.c_obj[id.from_name] = document.getElementById("txt_from_name").value;
      creditor.c_obj[id.from_address] = document.getElementById("txt_from_address").value;
      creditor.c_obj[id.registration_number] = document.getElementById("txt_identification_number").value;
      creditor.c_obj[id.amount_claimed] = secondScreen[id.amount_claimed];

      if(document.getElementById("txt_membership_expiry_date")){
        var dt = new Date(document.getElementById("txt_membership_expiry_date").value);
        creditor.c_obj["membership_expiry_date"] = dt.getDate() + "/" + (dt.getMonth() + 1) + "/" + dt.getFullYear();
      }

      creditor.f_obj.form_name = form_name;

      creditor.c_obj["identification_type"] = document.getElementById("dropdown_1").value;//dropDownPanValue;
      if(document.getElementById("dropdown-11")) {
        creditor.c_obj["insolvency_prof"] = document.getElementById("dropdown-11").value;
      }
      if(claim_no==undefined || claim_no==null || claim_no.length==0){
        creditor.c_obj["form_uploaded"] = "no"
      }
      if(document.getElementById("dropdown-12")){
        creditor.c_obj["related_corporate_debtor"] = document.getElementById("dropdown-12").value;
      }
      if(document.getElementById("txt_dispute_details")){
        creditor.c_obj["dispute_details"] = document.getElementById("txt_dispute_details").value;
      }

      creditor.c_obj["scheme_type"] = document.getElementById("scheme_type").value;
      creditor.c_obj["scheme_period"] = document.getElementById("scheme_period").value;
      creditor.c_obj["maturity_date"] = document.getElementById("txt_membership_maturity_date").value;

      //creditor.c_obj["idf_not_formca"] = document.getElementById("txt_identification_number_fb").value;
      if(document.getElementById("txt_amount_formc") != null) {
        creditor.c_obj["amount_formc"] = document.getElementById("txt_amount_formc").value;
      }

      if(document.getElementById("txt_reason_for_delay_filing") != null) {
        creditor.c_obj["reason_for_delay_filing"] = document.getElementById("txt_reason_for_delay_filing").value;

      }

      if(document.getElementById("amount_claimed").value.trim()=="" || 
        document.getElementById("amount_claimed").value.trim()=="0"){
        creditor.c_obj["amount_claimed"] = 0;
      }

      createCreditorSaveAsDraft(creditor.c_obj);
      return true;
    }
  };


  const formReset = () => {
    //  reset all the fields
    document.getElementById(id.form_id).reset();
    //  reset the uploaded forms
    setUploadedForms([]);
    //  reset the form attachments
    setformAttachments([]);
    setformUploadDeclataion([]);
    setformDeclataionIdentification([]);
    setformBankStatement([]);
    setformMemberOfCert([]);
    setformProofInterest([]);

    // console.log("form values reset");
  };
  const formSubmission = (e) => {
    e.preventDefault();
    //  console.log("before validation", secondScreen);
    validate();
  };

  const previewformpdf = (e) => {
    e.preventDefault();
    //   console.log("before validation previewformpdf : ", secondScreen);
    previewFormMethod();
  };

  // when we want to upload files through file control and we clicked on the block
  //  then corrosponding block will be displayed
  const openFileComponent = (fileControlId) => {
    document.getElementById(fileControlId).click();
  };

  // this funciton stores our files in our state
  const saveFiles = (files, fileControlId) => {
    console.log("saving", fileControlId)
    const filesArray = [];
    //  right now we are only taking the name of the file
    //  may be we have to take different values as well
    var formAttachmentBlock = false;
    if (fileControlId === id.form_attachments) {
      formAttachmentBlock = true;
    }

    var extension = ["pdf", "jpg", 'jpeg', 'png']
    var limit = 3 * 1024 * 1024

    Object.keys(files).map((fileIndex) => {
      const fileName = files[fileIndex].name.toLowerCase();
      var fileExtension = fileName.split(".")
      fileExtension = fileExtension[fileExtension.length - 1]

      const fileSize = files[fileIndex].size;
      const eachFile = files[fileIndex];
      const fileObj = { fileName, fileSize, eachFile };

      if ((extension.includes(fileExtension) && fileSize <= limit)) {
        //  if file name is already present then dont add again into the array
        //  to avoid duplicate files
        if (formAttachmentBlock) {
          if (!formAttachments.includes(fileName)) filesArray.push(fileObj);
        } else {
          var fileadds = true;
          if(fileadds){
            filesArray.push(fileObj);
          }
        }
      } else if (!extension.includes(fileExtension)){
        notification.validateuploadfiletypes();
      } else if (fileSize > limit){
        notification.validateuploadfilesize();
      }
    });
    //  no of files is greateer than 0 then save those files
    if (filesArray.length > 0) {
      if (fileControlId === id.uploaded_form) {
        //  save uploaded form in array
        setUploadedForms(filesArray);
      } else if (fileControlId === id.form_upload_declaration) {
        //  save form attachments files in array
        setformUploadDeclataion(filesArray);
      } else if (fileControlId === id.form_identification_proof) {
        //  save form attachments files in array
        setformDeclataionIdentification(filesArray);
      } else if (fileControlId === id.form_bank_account_stmt) {
        //  save form attachments files in array
        setformBankStatement(filesArray);
      } else if (fileControlId === id.form_membership_cert) {
        //  save form attachments files in array
        setformMemberOfCert(filesArray);
      } else if (fileControlId === id.form_proof_of_interest) {
        //  save form attachments files in array
        setformProofInterest(filesArray);
      } else if (fileControlId === id.form_attachments) {
        //  save form attachments files in array
        setformAttachments([...formAttachments, ...filesArray]);
      }
    }
  };
  //  our drag and drop component
  //  initially when we click on this component a file input function is called
  const DragDropUploadingBlock = (props) => {
    const { id: fileControlId } = props;

    //  this evert handler method will be required for the drag and drop operation
    const dragOver = (e) => {
      e.preventDefault();
    };
    //  this event handler method is used to handle when we drop files over the block
    const dropFile = (e, fileControlId) => {
      //  prevent the default behaviour
      e.preventDefault();
      //  get the files
      const files = e.dataTransfer.files;
      //   call the same saveFiles function which we used recently for saving the files with different id
      saveFiles(files, fileControlId);
    };
    return (
      <div
        className={`${style.uploadedFormButton} ${formValidationStatus
          ? id.uploaded_form === fileControlId && uploadedForms.length === 0
            ? style.error
            /*: id.form_attachments === fileControlId &&
              formAttachments.length === 0
              ? style.error*/
              : id.form_identification_proof === fileControlId && formDeclataionIdentification.length === 0
                ? style.error
                : ""
          : ""
          }`}
        onClick={() => openFileComponent(fileControlId)}
        onDragOver={dragOver}
        //  id represent through which drag and drop block we are receiving files
        onDrop={(e) => dropFile(e, fileControlId)}
      >
        <img src={dragIcon} alt="upload icon" />
        <p className={style.dragDropContent}>
          DRAG & DROP OR <span>SELECT</span>
        </p>
      </div>
    );
  };
  //  this component is used for uploading the file
  //  it is hidden by default
  const UploadFile = (props) => {
    //  no need to pass callbackfunction as a prop use id instead of that
    const { id: fileControlId, multiple } = props;

    return (
      <Form.Group hidden>
        <Form.File
          id={fileControlId}
          name={fileControlId}
          multiple={multiple}
          onChange={(e) => {
            const files = e.target.files;

            saveFiles(files, fileControlId);
          }}
        />
      </Form.Group>
    );
  };

  //  this component is used to create multiples input fields  based on files
  //  after uploading files this component is used to create input elements to display how manyy
  //  files we have uploaded.
  //  and gave the ability to remove that file through cross button
  const UploadedFormBlock = (props) => {
    //  id represents from which block this this function is calling
    // these are the only two options that we have right now  either uploadedForms and formAttachments
    //  fileName representing itself.

    const { fileControlId, fileName } = props;
    const removeFile = () => {
      if (fileControlId === id.uploaded_form) {
        const localArray = [...uploadedForms];

        localArray.splice(
          localArray.findIndex((fileObj) => fileObj.fileName === fileName),
          1
        );
        setUploadedForms(localArray);
      } else if (fileControlId === id.form_attachments) {
        const localArray = [...formAttachments];
        localArray.splice(
          localArray.findIndex((fileObj) => fileObj.fileName === fileName),
          1
        );
        setformAttachments(localArray);
      } else if (fileControlId === id.form_upload_declaration) {
        const localArray = [...formUploadDeclataion];

        localArray.splice(
          localArray.findIndex((fileObj) => fileObj.fileName === fileName),
          1
        );
        setformUploadDeclataion(localArray);
      } else if (fileControlId === id.form_identification_proof) {
        const localArray = [...formDeclataionIdentification];

        localArray.splice(
          localArray.findIndex((fileObj) => fileObj.fileName === fileName),
          1
        );
        setformDeclataionIdentification(localArray);
      } else if (fileControlId === id.form_bank_account_stmt) {
        const localArray = [...formBankStatement];

        localArray.splice(
          localArray.findIndex((fileObj) => fileObj.fileName === fileName),
          1
        );
        setformBankStatement(localArray);
      } else if (fileControlId === id.form_membership_cert) {
        const localArray = [...formMemberOfCert];

        localArray.splice(
          localArray.findIndex((fileObj) => fileObj.fileName === fileName),
          1
        );
        setformMemberOfCert(localArray);
      } else if (fileControlId === id.form_proof_of_interest) {
        const localArray = [...formProofInterest];

        localArray.splice(
          localArray.findIndex((fileObj) => fileObj.fileName === fileName),
          1
        );
        setformProofInterest(localArray);
      } else {
        //     console.log("shunya");
      }
    };
    return (
      <div className="mb-3 d-flex align-items-center">
        <Form.Control
          className={`${style.inputColor}`}
          type="text"
          style={{ paddingRight: "40px", color: "#6F6D73" }}
          defaultValue={fileName}
        />
        <span className={style.removeFileIcon} onClick={removeFile}>
          <img src={close} alt="Remove files" />
        </span>
      </div>
    );
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [confirmationStatus, setConfirmationStatus] = useState("");
  useEffect(() => {
    if (confirmationStatus !== "") {
      //   console.log("yes", confirmationStatus, creditorDetails);
      const obj = {
        creditorDetails,
        decryptedObject,
        nextScreen,
        focusCreditorField,
        confirmationStatus,
        fileUploadingObject,
      };
      onConfirmation(obj);
    }
  }, [confirmationStatus]);

  const showModal = (creditorFolderExist) => {
    if (creditorFolderExist === true) {
      //  just empty  the value before opening modal for confirmation
      setConfirmationStatus("");
      handleShow();
    } else {
      setConfirmationStatus("true");
    }
  };

  const windowPrint = () => {
    var htmlstr = document.body.innerHTML;
    document.querySelectorAll('.txtallclasses').forEach(function (el) {
      el.classList.add("displaynone");
    });
    document.querySelectorAll('.lblallclasses').forEach(function (el) {
      el.classList.remove("displaynone");
    });
    document.querySelectorAll('.custselectlist').forEach(function (el) {
      el.classList.add("displaynone");
    });
    document.querySelectorAll('.mandatorysigncls').forEach(function (el) {
      el.classList.add("displaynone");
    });
    if(document.getElementById("formCA")){
      document.body.innerHTML = document.getElementById("formCA").innerHTML;
    } else if(document.getElementById("formB")){
      document.body.innerHTML = document.getElementById("formB").innerHTML;
    } else if(document.getElementById("formC")){
      document.body.innerHTML = document.getElementById("formC").innerHTML;
    } else if(document.getElementById("formD")){
      document.body.innerHTML = document.getElementById("formD").innerHTML;
    } else if(document.getElementById("formF")){
      document.body.innerHTML = document.getElementById("formF").innerHTML;
    }
  
    // Print the page
    window.print();

    document.body.innerHTML = htmlstr;
    document.querySelectorAll('.lblallclasses').forEach(function (el) {
      el.classList.add("displaynone");
    });
    document.querySelectorAll('.txtallclasses').forEach(function (el) {
      el.classList.remove("displaynone");
    });
    document.querySelectorAll('.mandatorysigncls').forEach(function (el) {
      el.classList.remove("displaynone");
    });
    
  }
  /*const jsPDFPrint = () => {
    var pdfElement = document.getElementById("formCA");
    var doc = new jsPDF('p', 'mm', 'a4', true);
    doc.setFontSize(9)

    doc.html(pdfElement, {
      callback: (pdf) => {
        pdf.save("MyPdfFile.pdf");
      },
      width: 210,
      windowWidth: 210,

      html2canvas: {
        width: 210,

        windowWidth: 210,
      }
    });
  }*/
  const fun = () => {
    creditor.c_obj["form_downloaded"] = "yes"
    var claim_no = creditor.c_obj.claim_no;
    if(claim_no!=undefined && claim_no!=null && claim_no.length>0){
      creditor.c_obj["edit_done"] = "downloaded"
    }
    if (validatesaveasdraft()) {
      var body = { email_id: singlecreditorobj?.email_id, creditor_claim: singlecreditorobj?.creditor_claim };
      holdsesinfo(body);
      
      document.body.classList.add('zoom-reset');
      console.log("zoompresent set new pos---------------->");

      setTimeout(() => {
        windowPrint();
        
        // Remove the zoom-reset class after printing
        document.body.classList.remove('zoom-reset');

        window.location.reload();
      }, 1000); // Adjust the timeout duration if necessary
    }

  };

  const fbidentifyval = (e) => {
    document.getElementById("lbl_identification_doc").innerHTML = e.target.value;
  };

  const mouseDown = (e) => {
    setTimeout(function(){
      if(e.target.id=="txt_membership_number") notification.tooltipcustomerid();
      else if(e.target.id=="form_name") notification.tooltipsurrenderval();
      else if(e.target.id=="txt_identification_number") notification.tooltipidproof();
      else if(e.target.id=="amount_claimed") notification.tooltiptotalamount();
      else if(e.target.id=="creditor") notification.tooltipprincipalamount();
      else if(e.target.id=="txt_folio_number") notification.tooltipfolio();
      else if(e.target.id=="dropdown_1") notification.tooltipidprooftype();
      else if(e.target.id=="txt_membership_expiry_date") notification.tooltipexpirydate();
      else if(e.target.id=="txt_bnk_name") notification.tooltipbankname();
      else if(e.target.id=="txt_ifsc_code") notification.tooltipifsccode();
      else if(e.target.id=="txt_bnk_account") notification.tooltipbankaccount();
      else if(e.target.id=="txt_vendor_code") notification.tooltipvendorcode();
      else if(e.target.id=="txt_employee_code") notification.tooltipemployeecode();
      else if(e.target.id=="scheme_type") notification.tooltipschematype();
      else if(e.target.id=="scheme_period") notification.tooltipschemaperiod();
      else if(e.target.id=="beneficary_name") notification.tooltipsbeneficaryname();
    }, 200);
  };

  const elementBlur = (e) => {
    notification.hide();
    if(e.target.id=="txt_bnk_name" || e.target.id=="txt_ifsc_code" || e.target.id=="txt_bnk_account"){
      document.getElementById("txt_bank_account").value = document.getElementById("txt_bnk_name").value
      +","+document.getElementById("txt_ifsc_code").value+","+document.getElementById("txt_bnk_account").value
      document.getElementById("lbl_bank_account").innerHTML = document.getElementById("txt_bank_account").value;
    }
    console.log("elementBlur : " + e.target.id);
  };

  function checkidentificationtype(){
    var ret = true;
    if(document.getElementById("dropdown_1").value==""){
      ret = false;
    }
    return ret;
  }

  function refreshwindow() {
    window.location.reload();
  }
  //  this is our main component our entire form
  return (
    <>
      <p style={{color:"red", display:"none"}} id="p_edit_claim_note">
        [<strong>Note: I understand that information as provided in the claim form is complete and correct and no further edits will be allowed once submission is made, until further notice from Resolution Professional.</strong>]
      </p>

      <p style={{color:"red"}}>
        [<strong>Disclaimer - For Claims submitted after the expiry of the 
              90th day from the insolvency Commencement Date i.e. post 19.08.2024, 
              the RP has no power under the Code to consider claims received 
              after the scheduled date (19.08.2024) as provided under the Code. 
              Such delayed claim can be considered subject to condonation of delay 
              in submission of claim by the Adjudicating Authority i.e. 
              National Company Law Tribunal, Mumbai in the present case..</strong>]
      </p>

      <Form id="form_claim_status" className={`mt-5 mb-3 ${style.formStyle}`} style={{display:"none"}}>
        <Row>
          <Col xs="10">
            <Form.Group
              className={style.formGroup}>
              <Form.Label className={style.labelColor}>Claim ID</Form.Label>
              <Form.Control type="text" id="txt_clmsts_claim_id" disabled/>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs="10">
            <Form.Group
              className={style.formGroup}>
              <Form.Label className={style.labelColor}>Date of receipt of claim</Form.Label>
              <Form.Control type="text" id="txt_clmsts_claim_filed_date" disabled/>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs="10">
            <Form.Group
              className={style.formGroup}>
              <Form.Label className={style.labelColor}>Name of Claimant</Form.Label>
              <Form.Control type="text" id="txt_clmsts_claimant_name" disabled/>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs="10">
            <Form.Group
              className={style.formGroup}>
              <Form.Label className={style.labelColor}>Identification ID</Form.Label>
              <Form.Control type="text" id="txt_clmsts_identification_no" disabled/>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs="10">
            <Form.Group
              className={style.formGroup}>
              <Form.Label className={style.labelColor}>Amount claimed</Form.Label>
              <Form.Control type="text" id="txt_clmsts_amount_claimed" disabled/>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs="10">
            <Form.Group
              className={style.formGroup}>
              <Form.Label className={style.labelColor}>Amount Admitted</Form.Label>
              <Form.Control type="text" id="txt_clmsts_amount_admitted" disabled/>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs="10">
            <Form.Group
              className={style.formGroup}>
              <Form.Label className={style.labelColor}>Amount of claim under verification</Form.Label>
              <Form.Control type="text" id="txt_clmsts_amount_under_verify" disabled/>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs="10">
            <Form.Group
              className={style.formGroup}>
              <Form.Label className={style.labelColor}>Amount of claim not admitted</Form.Label>
              <Form.Control type="text" id="txt_clmsts_amount_not_admitted" disabled/>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs="10">
            <Form.Group
              className={style.formGroup}>
              <Form.Label className={style.labelColor}>Whether related party?</Form.Label>
              <Form.Control type="text" id="txt_clmsts_related_party" disabled/>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs="10">
            <Form.Group
              className={style.formGroup}>
              <Form.Label className={style.labelColor}>% of voting share in CoC</Form.Label>
              <Form.Control type="text" id="txt_clmsts_coc_voter_percentage" disabled/>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs="10">
            <Form.Group
              className={style.formGroup}>
              <Form.Label className={style.labelColor}>Remarks</Form.Label>
              <Form.Control type="text" id="txt_clmsts_remark" disabled/>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs="10">
            <Form.Group
              className={style.formGroup}>
              <Form.Label className={style.labelColor}>Modify Status</Form.Label>
              <Form.Control type="text" id="txt_modify_status" disabled/>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs="10">
            <Form.Group
              className={style.formGroup}>
              <div>
              <span style={{color:"red", fontWeight:"bold"}}>Note to claim status: </span>
              <span style={{fontWeight:"normal"}}>Considering challenges with respect to verification of claims, including but not limited to – no access to the records and books of the Corporate Debtor, voluminous claims,  non-availability of any information with respect to Corporate Debtor, absence of any Corporate Debtor employees/management, the RP is currently relying upon the documents and information provided by the claimant to verify the claims provisionally on a best estimate basis and the status of these claims shall be updated in due process subject to availability of relevant information of Corporate Debtor.
In terms of Regulation 12(2), where a claim is not filed within the time stipulated in the Public Announcement, the claim can be permitted to be filed on or before the 90th day of Insolvency Commencement Date i.e., on or before December 8, 2022, in the present case. The outer limit of 90 days-time period for filing of claim as stipulated under Regulation 12(2) of the CIRP Regulations has expired on December 8, 2022 and therefore status of claims as received post December 8, 2022 cannot be accessed.
Claimants submitting claim on or before December 8, 2022, can edit / modify claim by visiting following link;
[<a href="javascript:void(0);" onClick={refreshwindow}>Click here</a>]
</span>.
              </div>
            </Form.Group>
          </Col>
        </Row>
      </Form>

      <Form id="form_view_doc" className={`mt-5 mb-3 ${style.formStyle}`} style={{display:"none"}}>
        <Row>
          <Col xs="10">
            <Form.Group
              className={style.formGroup}>
              {/*<input type="checkbox" id="chk_disclaimer" />*/}
              <div>
                I am being granted access to the confidential data contained on this data room.
                I understand that my access to this data room is subjected to	certain conditions.
                All the information on this data room is considered confidential and is subject to
                confidentiality agreement between my company and owner of this data room.
                I will maintain the information in confidence and will not disclose information to
                others except as expressly permitted by the Confidentiality Agreement.
                I will not attempt to capture any of the information contained in the data room
                except the one for which i have been permitted by the owner of the data room.
                I will not allow others to get access to the data room using my credentials.
                Please click Accept to agree to the above condition.<br/>
                I am being granted access to the confidential data contained on this data room.
                I understand that my access to this data room is subjected to	certain conditions.
                All the information on this data room is considered confidential and is subject to
                confidentiality agreement between my company and owner of this data room.
                I will maintain the information in confidence and will not disclose information to
                others except as expressly permitted by the Confidentiality Agreement.
                I will not attempt to capture any of the information contained in the data room
                except the one for which i have been permitted by the owner of the data room.
                I will not allow others to get access to the data room using my credentials.
                Please click Accept to agree to the above condition.<br/>
                I am being granted access to the confidential data contained on this data room.
                I understand that my access to this data room is subjected to	certain conditions.
                All the information on this data room is considered confidential and is subject to
                confidentiality agreement between my company and owner of this data room.
                I will maintain the information in confidence and will not disclose information to
                others except as expressly permitted by the Confidentiality Agreement.
                I will not attempt to capture any of the information contained in the data room
                except the one for which i have been permitted by the owner of the data room.
                I will not allow others to get access to the data room using my credentials.
                Please click Accept to agree to the above condition.<br/>
                I am being granted access to the confidential data contained on this data room.
                I understand that my access to this data room is subjected to	certain conditions.
                All the information on this data room is considered confidential and is subject to
                confidentiality agreement between my company and owner of this data room.
                I will maintain the information in confidence and will not disclose information to
                others except as expressly permitted by the Confidentiality Agreement.
                I will not attempt to capture any of the information contained in the data room
                except the one for which i have been permitted by the owner of the data room.
                I will not allow others to get access to the data room using my credentials.
                Please click Accept to agree to the above condition.<br/>
                I am being granted access to the confidential data contained on this data room.
                I understand that my access to this data room is subjected to	certain conditions.
                All the information on this data room is considered confidential and is subject to
                confidentiality agreement between my company and owner of this data room.
                I will maintain the information in confidence and will not disclose information to
                others except as expressly permitted by the Confidentiality Agreement.
                I will not attempt to capture any of the information contained in the data room
                except the one for which i have been permitted by the owner of the data room.
                I will not allow others to get access to the data room using my credentials.
                Please click Accept to agree to the above condition.
              </div>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs="10">
            <Form.Group className={style.formGroup}>
            <Button className={style.nextButton} id="btn_doc_download" onClick={() => {
                startDownloadZip();
              }} style={{ marginLeft: '5px', marginTop:'10px' }}>
              Download
            </Button>
            </Form.Group>
          </Col>
        </Row>
      </Form>

      <Form
        id={id.form_id}
        className={`mt-5 mb-3 ${style.formStyle}`}
        onSubmit={formSubmission}>
        {/*  the first 3 rows are our form fields */}
        <Row id="row_idf_claimno" style={{"display":"none"}}>
          <Col xs="10" sm="5" lg="10">
            <Form.Group controlId={id.identification_number} className={style.formGroup}>
              <Form.Label className={style.labelColor}>
                Claim Number
              </Form.Label>
              <Form.Control
                id={"txt_idf_claimno"}
                type="text"
                disabled
              />
            </Form.Group>
          </Col>
        </Row>


        <Row>
          <Col xs="10" sm="5" lg="10">
            <Form.Group controlId={id.identification_number} className={style.formGroup}>
              <Form.Label className={style.labelColor}>
                Reasons for delay in filing claim
              </Form.Label>
              <Form.Control
                id={"txt_reason_for_delay_filing"}
                type="text"
              />
            </Form.Group>
          </Col>
        </Row>


        <Row id="row_idf_formca">
          <Col xs="10" sm="5" lg="5">
            <Form.Group
              className={style.formGroup}
              onChange={handleChange}>
              <Form.Label className={style.labelColor}>
                <span className={"mandatorysigncls mandatorysignclsempty"}>*</span>Identification Proof
              </Form.Label>
              <select aria-label="Default select example" id="dropdown_1"
                style={{ "width": "174px", "height": "37px", "font-size": "16px", "font-family": "Montserrat, sans-serif" }}
                className={`${formValidationStatus &&
                  !checkidentificationtype() &&
                  style.error
                  } ${style.inputColor}`}
                name={id.dropdown_1_name}
                onMouseDown={mouseDown} onBlur={elementBlur}>
                <option value={""}>Select</option>
                <option value={"PAN"}>PAN</option>
                <option value={"Adhaar"}>Adhaar</option>
                <option value={"Passport"}>Passport</option>
                <option value={"Voter Id"}>Voter Id</option>
              </select>
            </Form.Group>
          </Col>
          <Col xs="10" sm="5" lg="5" id="col_dropdown_idf_formca">
            <Form.Group controlId={id.identification_number} className={style.formGroup}>
              <Form.Label className={style.labelColor}>
                <span className={"mandatorysigncls mandatorysignclsempty"}>*</span>Identification Proof Number
              </Form.Label>
              <Form.Control
                className={`${formValidationStatus &&
                  !validateIdentificationNobool() &&
                  style.error
                  } ${style.inputColor}`}
                name={id.identification_number}
                id={"txt_identification_number"}
                type="text"
                onChange={identificationnochange}
                onBlur={identificationnoblur}
                onMouseDown={mouseDown}
                required
              />
            </Form.Group>
          </Col>
        </Row>
        <Row id="row_idf_not_formca" style={{"display":"none"}}>
          <Col xs="10" sm="5" lg="10" id="col_dropdown_idf_formca">
            <Form.Group controlId={id.identification_number_formb} className={style.formGroup}>
              <Form.Label className={style.labelColor}>
                <span className={"mandatorysigncls mandatorysignclsempty"}>*</span>
                <span id="span_idf_proof_number">Identification Proof Number</span>
              </Form.Label>
              <Form.Control
                className={`${formValidationStatus && style.error
                  } ${style.inputColor}`}
                name={id.identification_number_formb}
                id={"txt_identification_number_fb"}
                type="text"
                onChange={fbidentifyval}
                onMouseDown={mouseDown}
                required
              />
            </Form.Group>
          </Col>
        </Row>
        <Row id="row_folio_number">
          <Col xs="10" sm="5" lg="5">
            <Form.Group
              className={style.formGroup}
              onChange={handleChange}
              controlId={id.folio_no_name}>
              <Form.Label className={style.labelColor}>
                <span className={"mandatorysigncls mandatorysignclsempty"}>*</span>FDR Number
              </Form.Label>
              <Form.Control
                required
                className={`${formValidationStatus &&
                  !secondScreen[id.folio_no_name] &&
                  style.error
                  } ${style.inputColor} webkitinnernumber webkitouternumber`}
                id="txt_folio_number"
                name={id.folio_no_name}
                minength="12"
                maxLength="12"
                type="number"
                onMouseDown={mouseDown} onBlur={elementBlur}
              />
            </Form.Group>
          </Col>
          <Col xs="10" sm="5" lg="5">
            <Form.Group
              className={style.formGroup}
              onChange={handleChange}
              controlId={id.membership_number}>
              <Form.Label className={style.labelColor}>
              <span className={"mandatorysigncls mandatorysignclsempty"}>*</span>Customer Id
              </Form.Label>
              <Form.Control
                required
                className={`${formValidationStatus &&
                  !secondScreen[id.membership_number] &&
                  style.error
                  } ${style.inputColor} webkitinnernumber webkitouternumber`}
                type="text"
                name={id.membership_number}
                id="txt_membership_number"
                onMouseDown={mouseDown} onBlur={elementBlur}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs="10" sm="5" lg="5">
            <Form.Group
              className={style.formGroup}
              onChange={validatamtevent}
              controlId={id.creditor}>
              <Form.Label className={style.labelColor}>
                <span className={"mandatorysigncls mandatorysignclsempty"}>*</span>
                <span id="span_deposit_amount">Deposit Amount</span>
              </Form.Label>
              <Form.Control
                required
                className={`${formValidationStatus &&
                  !secondScreen[id.creditor] &&
                  style.error
                  } ${style.inputColor} webkitinnernumber webkitouternumber`}
                name={id.creditor}
                type="number"
                onMouseDown={mouseDown} onBlur={elementBlur}
                min="1"
                max="9999999"
              />
            </Form.Group>
          </Col>
          <Col xs="10" sm="5" lg="5">
            <Form.Group
              onChange={validatamtevent}
              controlId={id.amount_claimed}
              className={style.formGroup}>
              <Form.Label className={style.labelColor}>
                <span className={"mandatorysigncls mandatorysignclsempty"} id="span_amturity_amount_red">*</span>
                <span id="span_amturity_amount">Maturity Amount</span>
              </Form.Label>
              <Form.Control
                className={`${formValidationStatus &&
                  !secondScreen[id.amount_claimed] &&
                  style.error
                  } ${style.inputColor}`}
                name={id.amount_claimed}
                type="number"
                required
                onMouseDown={mouseDown} onBlur={elementBlur}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row id="row_scheme">
          <Col xs="10" sm="5" lg="5">
            <Form.Group
              className={style.formGroup}
              onChange={handleChange}>
              <Form.Label className={style.labelColor} style={{"width":"130px"}}>
                <span className={"mandatorysigncls mandatorysignclsempty"}>*</span>Scheme
              </Form.Label>
              <select aria-label="Default select example" id="scheme_type"
                style={{ "width": "274px", "height": "37px", "font-size": "16px", "font-family": "Montserrat, sans-serif" }}
                name={id.scheme_type}
                onMouseDown={mouseDown} onBlur={elementBlur}>
                <option value={"Cumulative"}>Cumulative</option>
                <option value={"Non Cumulative"}>Non Cumulative</option>
              </select>
            </Form.Group>
          </Col>
          <Col xs="10" sm="5" lg="5">
            <Form.Group
              className={style.formGroup}
              onChange={handleChange}>
              <Form.Label className={style.labelColor} style={{"width":"130px"}}>
                <span className={"mandatorysigncls mandatorysignclsempty"}>*</span>Period
              </Form.Label>
              <select aria-label="Default select example" id="scheme_period"
                style={{ "width": "274px", "height": "37px", "font-size": "16px", "font-family": "Montserrat, sans-serif" }}
                name={id.scheme_period}
                onMouseDown={mouseDown} onBlur={elementBlur}>
                <option value={"1"}>1 (yr)s</option>
                <option value={"2"}>2 (yr)s</option>
                <option value={"3"}>3 (yr)s</option>
              </select>
            </Form.Group>
          </Col>
        </Row>

        <Row id="row_membership_value_exp_date_surrender_val">
          <Col xs="10" sm="5" lg="5">
            <Form.Group onBlur={validatExpiryDateEvent} className={style.formGroup}
              controlId={id.memebership_expiry_date}>
              <Form.Label className={style.labelColor}>
              <span className={"mandatorysigncls mandatorysignclsempty"}>*</span>Date of Deposit
              </Form.Label>
              <Form.Control type="date" id="txt_membership_expiry_date" min='1997-01-01' max='2030-12-31'
              className={`${formValidationStatus &&
                !secondScreen[id.memebership_expiry_date] &&
                style.error
                } ${style.inputColor} webkitinnernumber webkitouternumber`}
              onMouseDown={mouseDown} onBlur={elementBlur} onChange={handleChange}
              name={id.memebership_expiry_date}/>
            </Form.Group>
          </Col>
          <Col xs="10" sm="5" lg="5">
            <Form.Group className={style.formGroup}>
              <Form.Label className={style.labelColor}>Date of Maturity</Form.Label>
              <Form.Control type="text" id="txt_membership_maturity_date" readOnly/>
              {/*<Form.Control type="date" id="txt_membership_expiry_date" />*/}
            </Form.Group>
          </Col>
          <Col xs="10" sm="5" lg="5">
            <Form.Group
              className={style.formGroup}
              controlId={id.form_name}
              onChange={validatamtevent}
              style={{display:"none"}}>
              <Form.Label className={style.labelColor}>
                Surrender Value
              </Form.Label>
              <Form.Control
                // className={`${formValidationStatus &&
                //   !secondScreen[id.form_name] &&
                //   style.error
                //   } ${style.inputColor}`}
                name={id.form_name}
                type="number"
                onMouseDown={mouseDown} onBlur={elementBlur}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs="10" sm="5" lg="10">
            <Form.Group
              onChange={handleChange}
              className={style.formGroup}
              controlId={id.txt_bnk_name}>
              <Form.Label className={style.labelColor}>
                <span className={"mandatorysigncls mandatorysignclsempty"}>*</span>Bank Name
              </Form.Label>
              <Form.Control
                id="txt_bnk_name"
                required
                className={`${formValidationStatus &&
                  !secondScreen[id.txt_bnk_name] &&
                  style.error
                  } ${style.inputColor} webkitinnernumber webkitouternumber`}
                onMouseDown={mouseDown} onBlur={elementBlur}
                name={id.txt_bnk_name}
              />
            </Form.Group>
          </Col>
        </Row>

        
        <Row>
          <Col xs="10" sm="5" lg="10">
            <Form.Group
              onChange={handleChange}
              className={style.formGroup}
              controlId={id.beneficary_name}>
              <Form.Label className={style.labelColor}>
                <span className={"mandatorysigncls mandatorysignclsempty"}>*</span>Beneficiary Name
              </Form.Label>
              <Form.Control
                id="beneficary_name"
                required
                className={`${formValidationStatus &&
                  !secondScreen[id.beneficary_name] &&
                  style.error
                  } ${style.inputColor} webkitinnernumber webkitouternumber`}
                onMouseDown={mouseDown} onBlur={elementBlur}
                name={id.beneficary_name}
              />
            </Form.Group>
          </Col>
        </Row>



        <Row>
          <Col xs="10" sm="5" lg="5">
            <Form.Group
              onChange={handleChange}
              className={style.formGroup}
              controlId={id.txt_ifsc_code}>
              <Form.Label className={style.labelColor}>
                <span className={"mandatorysigncls mandatorysignclsempty"}>*</span>IFSC Code
              </Form.Label>
              <Form.Control
                id="txt_ifsc_code"
                required
                minLength="11"
                maxLength="11"
                onMouseDown={mouseDown} onBlur={elementBlur}
                className={`${formValidationStatus &&
                  !secondScreen[id.txt_ifsc_code] &&
                  style.error
                  } ${style.inputColor} webkitinnernumber webkitouternumber`}
                name={id.txt_ifsc_code}
              />
            </Form.Group>
          </Col>
          <Col xs="10" sm="5" lg="5">
            <Form.Group
              onChange={handleChange}
              className={style.formGroup}>
              <Form.Label className={style.labelColor}>
                <span className={"mandatorysigncls mandatorysignclsempty"}>*</span>Account No
              </Form.Label>
              <Form.Control
                id="txt_bnk_account"
                required
                minLength="9"
                maxLength="18"
                type="number"
                onMouseDown={mouseDown} onBlur={elementBlur}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row id="row_vendor_formb" style={{"display":"none"}}>
          <Col xs="10" sm="5" lg="5">
            <Form.Group
              onChange={handleChange}
              className={style.formGroup}
              controlId={id.vendor_code}>
              <Form.Label className={style.labelColor}>
                <span className={"mandatorysigncls mandatorysignclsempty"}>*</span>Vendor Code
              </Form.Label>
              <Form.Control
                id="txt_vendor_code"
                className={`${formValidationStatus &&
                  !secondScreen[id.vendor_code] &&
                  style.error
                  } ${style.inputColor} webkitinnernumber webkitouternumber`}
                name={id.vendor_code}
                onMouseDown={mouseDown}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row id="row_employee_code_formd_forme" style={{"display":"none"}}>
          <Col xs="10" sm="5" lg="5">
            <Form.Group
              onChange={handleChange}
              className={style.formGroup}
              controlId={id.employee_code}>
              <Form.Label className={style.labelColor}>
                <span className={"mandatorysigncls mandatorysignclsempty"}>*</span>Employee Code
              </Form.Label>
              <Form.Control
                id="txt_employee_code"
                className={`${formValidationStatus &&
                  !secondScreen[id.employee_code] &&
                  style.error
                  } ${style.inputColor} webkitinnernumber webkitouternumber`}
                name={id.employee_code}
                onMouseDown={mouseDown}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>

        </Row>

        <FormCA secondScreen={secondScreen}
          singlecreditorobj={singlecreditorobj}
        />
        <FormB secondScreen={secondScreen}
          singlecreditorobj={singlecreditorobj}
        />
        <FormC secondScreen={secondScreen}
          singlecreditorobj={singlecreditorobj}
        />
        <FormD secondScreen={secondScreen}
          singlecreditorobj={singlecreditorobj}
        />
        <FormF secondScreen={secondScreen}
          singlecreditorobj={singlecreditorobj}
        />

        <p style={{color:"red"}}>[<strong>Note: After downloading the filled in Form, the Upload & Claim Button is enabled for users.</strong>]
        </p>
        <p style={{color:"red"}}>[<strong>Note: Before clicking Upload and Claim, please make sure to upload all necessary documents. Once the claim has been uploaded, you won't be able to update any data or upload any documents.</strong>]
        </p>

        <div className="d-flex justify-content-center mb-4">
          <Button className="mr-1 px-5" id="btn_saveasdraft" onClick={() => {
              creditor.c_obj["edit_done"] = "draft"
              validatesaveasdraft();
          }}>Save as draft</Button>
          <Button onClick={fun} id="btn_downloadprint">Download, Print and Sign</Button>
        </div>

        {/*  our 4th and 5th rows are for uploading forms and form attachments */}
        <Row id="row_file_identification">
          <Col xs="11" lg="7" xl="9">
            <Form.Group
              className={style.formGroup}
              onChange={handleChange}>
              <div className={style.addNewAttachmentBlock}>
                <Form.Label className={style.labelColor}>
                  <span className={"mandatorysigncls mandatorysignclsempty"}>*</span>
                  <span style={{color:"red"}}>Upload mentioned identification proof with identification number clearly visible</span> <br />
                  UPLOAD IDENTIFICATION PROOF (Only pdf, jpg, jpeg, png) <br />
                  Maximum Upload Size 3MB
                </Form.Label>
                <span
                  onClick={() =>
                    openFileComponent(id.form_identification_proof)
                  }
                  className={style.addNewAttachments}
                ></span>
              </div>
              <UploadFile id={id.form_identification_proof} multiple={false} />
              {formDeclataionIdentification.length > 0 ? (
                <>
                  {formDeclataionIdentification.map((fileObj, fileIndex) => {
                    form_upload_idft.upload_identification_proof = "Yes";
                    return (
                      <UploadedFormBlock
                        key={fileIndex}
                        fileControlId={id.form_identification_proof}
                        fileName={fileObj.fileName}
                      />
                    );
                  })}
                </>
              ) : (
                <DragDropUploadingBlock id={id.form_identification_proof} />
              )}
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-4">
          <Col xs="11" lg="7" xl="9">
            <Form.Group
              className={style.formGroup}
              onChange={handleChange}>
              <Form.Label className={style.labelColor}>
                <span id="row_form_upload_mandatory_sign" className={"mandatorysigncls mandatorysignclsempty"}>*</span>
                UPLOAD SIGNED FORM & DECLARATION (Only pdf, jpg, jpeg, png)<br />
                Maximum Upload Size 3MB 
              </Form.Label>
              <UploadFile id={id.uploaded_form} multiple={true} />
              {uploadedForms.length > 0 ? (
                <>
                  {uploadedForms.map((fileObj, fileIndex) => {
                    return (
                      <UploadedFormBlock
                        key={fileIndex}
                        fileControlId={id.uploaded_form}
                        fileName={fileObj.fileName}
                      />
                    );
                  })}
                </>
              ) : (
                <DragDropUploadingBlock id={id.uploaded_form} />
              )}
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col xs="11" lg="7" xl="9">
            <Form.Group
              className={style.formGroup}
              onChange={handleChange}>
              <div className={style.addNewAttachmentBlock}>
                <Form.Label className={style.labelColor}>
                  <span className={"mandatorysigncls mandatorysignclsempty"}>*</span>
                  Upload Bank Proof (Cheque, Passbook etc)
                </Form.Label>
                <span
                  onClick={() => openFileComponent(id.form_upload_declaration)}
                  className={style.addNewAttachments}
                ></span>
              </div>
              <UploadFile id={id.form_upload_declaration} multiple={false} />
              {formUploadDeclataion.length > 0 ? (
                <>
                  {formUploadDeclataion.map((fileObj, fileIndex) => {
                    form_upload_idft.upload_declaration = "Yes";
                    return (
                      <UploadedFormBlock
                        key={fileIndex}
                        fileControlId={id.form_upload_declaration}
                        fileName={fileObj.fileName}
                      />
                    );
                  })}
                </>
              ) : (
                <DragDropUploadingBlock id={id.form_upload_declaration} />
              )}
            </Form.Group>
          </Col>
        </Row>

        <Row id="row_file_upload_bank123" style={{"display":"none"}}>
          <Col xs="11" lg="7" xl="9">
            <Form.Group
              className={style.formGroup}
              onChange={handleChange}
            //     controlId={id.form_attachments}
            >
              <div className={style.addNewAttachmentBlock}>
                <Form.Label className={style.labelColor}>
                  PROOF OF DEBT/PAYMENT (BANK STATEMENT) (Only pdf, jpg, jpeg, png)<br />
                  Maximum Upload Size 3MB
                </Form.Label>
                <span
                  onClick={() => openFileComponent(id.form_bank_account_stmt)}
                  className={style.addNewAttachments}
                ></span>
              </div>
              <UploadFile id={id.form_bank_account_stmt} multiple={false} />
              {formBankStatement.length > 0 ? (
                <>
                  {formBankStatement.map((fileObj, fileIndex) => {
                    form_upload_idft.bank_account_statement = "Yes";
                    return (
                      <UploadedFormBlock
                        key={fileIndex}
                        fileControlId={id.form_bank_account_stmt}
                        fileName={fileObj.fileName}
                      />
                    );
                  })}
                </>
              ) : (
                <DragDropUploadingBlock id={id.form_bank_account_stmt} />
              )}
            </Form.Group>
          </Col>
        </Row>

        <Row id="row_file_upload_membership_cert">
          <Col xs="11" lg="7" xl="9">
            <Form.Group
              className={style.formGroup}
              onChange={handleChange}
            //     controlId={id.form_attachments}
            >
              <div className={style.addNewAttachmentBlock}>
                <Form.Label className={style.labelColor}>
                <span className={"mandatorysigncls mandatorysignclsempty"} id="row_file_upload_membership_cert_mand">*</span>
                  FDR CERTIFICATE (Only pdf, jpg, jpeg, png)<br />
                  Maximum Upload Size 3MB
                </Form.Label>
                <span
                  onClick={() => openFileComponent(id.form_membership_cert)}
                  className={style.addNewAttachments}
                ></span>
              </div>
              <UploadFile id={id.form_membership_cert} multiple={false} />
              {formMemberOfCert.length > 0 ? (
                <>
                  {formMemberOfCert.map((fileObj, fileIndex) => {
                    form_upload_idft.membership_certificate = "Yes";
                    return (
                      <UploadedFormBlock
                        key={fileIndex}
                        fileControlId={id.form_membership_cert}
                        fileName={fileObj.fileName}
                      />
                    );
                  })}
                </>
              ) : (
                <DragDropUploadingBlock id={id.form_membership_cert} />
              )}
            </Form.Group>
          </Col>
        </Row>

        <Row id="row_file_upload_proof_interest123" style={{"display":"none"}}>
          <Col xs="11" lg="7" xl="9">
            <Form.Group
              className={style.formGroup}
              onChange={handleChange}
            //     controlId={id.form_attachments}
            >
              <div className={style.addNewAttachmentBlock}>
                <Form.Label className={style.labelColor}>
                  PROOF OF INTEREST (Only pdf, jpg, jpeg, png) <br />
                  Maximum Upload Size 3MB
                </Form.Label>
                <span
                  onClick={() => openFileComponent(id.form_proof_of_interest)}
                  className={style.addNewAttachments}
                ></span>
              </div>
              <UploadFile id={id.form_proof_of_interest} multiple={false} />
              {formProofInterest.length > 0 ? (
                <>
                  {formProofInterest.map((fileObj, fileIndex) => {
                    form_upload_idft.proof_of_interest = "Yes";
                    return (
                      <UploadedFormBlock
                        key={fileIndex}
                        fileControlId={id.form_proof_of_interest}
                        fileName={fileObj.fileName}
                      />
                    );
                  })}
                </>
              ) : (
                <DragDropUploadingBlock id={id.form_proof_of_interest} />
              )}
            </Form.Group>
          </Col>
        </Row>

        <Row id="row_file_other_attachments">
          <Col xs="11" lg="7" xl="9">
            <Form.Group
              className={style.formGroup}
              onChange={handleChange}
            //     controlId={id.form_attachments}
            >
              <div className={style.addNewAttachmentBlock}>
                <Form.Label className={style.labelColor}>
                  {/*<span className={"mandatorysigncls mandatorysignclsempty"}>*</span>*/}
                  OTHER ATTACHMENTS (Only pdf, jpg, jpeg, png) <br />
                  Maximum Upload Size 3MB
                </Form.Label>
                <span
                  onClick={() => openFileComponent(id.form_attachments)}
                  className={style.addNewAttachments}
                >
                  ADD MORE
                </span>
              </div>
              <UploadFile id={id.form_attachments} multiple={true} />
              {formAttachments.length > 0 ? (
                <>
                  {formAttachments.map((fileObj, fileIndex) => {
                    return (
                      <UploadedFormBlock
                        key={fileIndex}
                        fileControlId={id.form_attachments}
                        fileName={fileObj.fileName}
                      />
                    );
                  })}
                </>
              ) : (
                <DragDropUploadingBlock id={id.form_attachments} />
              )}
            </Form.Group>
          </Col>
        </Row>

        {/*  our last row that is 6th is a button we have to click when we submit all the fields  */}
        <Row className={style.uploadRow}>
          <Col xs="11">
            <Button type="submit" className={style.uploadButton} id="btn_upload_claim" style={{display:"none"}}>
              UPLOAD & SUBMIT CLAIM
            </Button>
          </Col>
        </Row>

        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>ARE YOU SURE ?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            This creditor already exists. Do you want to save the files to the
            same folder ?
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                setConfirmationStatus("true");
                handleClose();
              }}
            >
              YES
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                setConfirmationStatus("false");
                handleClose();
              }}
            >
              NO
            </Button>
          </Modal.Footer>
        </Modal>
      </Form>
    </>
  );
};

export default UploadScreen;
